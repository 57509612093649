import styled from "styled-components";

let titleBarHeight = 60;

export const OverviewBigScreenWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: #FFFFFF;
    letter-spacing: 2px;
    background-color: #2A2A2A;
    font-family: Rajdhani;

    &:before {
        pointer-events: none;
        content: '';
        position: absolute;
        z-index: 100;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 6px 120px 180px rgb(5, 12, 38), inset -48px -48px 150px rgb(5, 12, 38);
    }
`;

export const OverviewBigScreenInnerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 850px;
    color: #FFFFFF;
    letter-spacing: 2px;

    & .ant-carousel {
        height: 100%;
        overflow: hidden;
    }

    & .slick-list {
        height: 100%;
        overflow: hidden;
    }

    & .slick-track {
        height: 100%;
        overflow: hidden;
    }

    & .slick-slide > div {
        height: 100%;
        overflow: hidden;
    }

    & .slick-slide > div > div {
        height: 100%;
        overflow: hidden;
    }
`;

export const ScreenBorderTop = styled.img`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: 100;
`;

export const ScreenBorderBottom = styled.img`
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: auto;
    z-index: 100;
`;

export const DeviceMapWrapper = styled.div`
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
`;

export const ScreenTitleBar = styled.div`
    position: absolute;
    z-index: 110;
    left: 0%;
    top: 0%;
    width: 100%;
    height: ${titleBarHeight-10}px;
    padding: 10px;
    margin-top: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`;

export const DeviceOnlineStatisticsWrapper = styled.div`
    position: absolute;
    z-index: 101;
    left: 0%;
    top: ${titleBarHeight}px;
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    border-radius: 3px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.1);
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 30px) 100%, 0% 100%);

    &::after {
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 30px) 100%, 0% 100%);
    }
`;

export const DeviceListWrapper = styled.div`
    position: absolute;
    z-index: 101;
    left: 0%;
    top: calc((100% - ${titleBarHeight}px) * 1 / 3 + ${titleBarHeight}px);
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.1);
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 30px) 100%, 0% 100%);

    &::after {
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 30px) 100%, 0% 100%);
    }
`;

export const DeivceOnlineDurationWrapper = styled.div`
    position: absolute;
    z-index: 101;
    left: 0%;
    top: calc((100% - ${titleBarHeight}px) * 2 / 3 + ${titleBarHeight}px);
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 0 0 20px rgba(64, 64, 64, .2) inset;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.1);
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 30px) 100%, 0% 100%);

    &::after {
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 30px) 100%, 0% 100%);
    }
`;

export const DeviceAlarmStatisticsWrapper = styled.div`
    position: absolute;
    z-index: 101;
    right: 0%;
    top: ${titleBarHeight}px;
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.1);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 30px 100%, 0% calc(100% - 25px));

    &::after {
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 30px 100%, 0% calc(100% - 25px));
    }
`;

export const DeviceAlarmsWrapper = styled.div`
    position: absolute;
    z-index: 101;
    right: 0%;
    top: calc((100% - ${titleBarHeight}px) * 1 / 3 + ${titleBarHeight}px);
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.1);
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 30px) 100%, 0% 100%);

    &::after {
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 30px) 100%, 0% 100%);
    }
`;

export const DeviceTrafficStatisticsWrapper = styled.div`
    position: absolute;
    z-index: 101;
    right: 0%;
    top: calc((100% - ${titleBarHeight}px) * 2 / 3 + ${titleBarHeight}px);
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 0 0 20px rgba(64, 64, 64, .2) inset;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.1);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 30px 100%, 0% calc(100% - 25px));

    &::after {
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 30px 100%, 0% calc(100% - 25px));
    }
`;
