import { useEffect, useRef, useState } from "react";
import { Content, DeviceListWrapper, DeviceName, DeviceRssiValue, DeviceStatus, DeviceHealth, OfflineStatus, OnlineStatus, StatusText, TableHeader, TableHeaderRow, TableContent, TableRow } from "./DeviceList.styled";
import DeviceApi from "../../../../../api/DeviceApi";
import EventManager from "../../common/event-manager/EventManager";
import StringUtils from "../../../../../util/StringUtils";
import AutoScrollUtils from "../../../../../util/AutoScrollUtils";
import DeviceRssi from "../../../../../components/ui-components/rssi/DeviceRssi";
import CountUp from "react-countup";

interface DeviceListProprs {
    eventManager: EventManager
}

export const DeviceList: React.FC<DeviceListProprs> = (props)=> {
    // api
    let deviceApi = new DeviceApi();

    // ref
    let carouselRef = useRef<any>();

    // state
    let [deviceViews, setDeviceViews] = useState([]);

    // event
    let onDeviceSelected = (deviceView)=> {
        props.eventManager.notifyDeviceSelected(deviceView.device.id);
    };

    // onload
    useEffect(()=> {
        let loadDeviceViews = async ()=> {
            let deviceViews = (await deviceApi.getAllDeviceViews()).data.data;
            deviceViews = deviceViews.sort((a, b)=> {
                if (a.node.status != b.node.status) {
                    if (StringUtils.equalsIgnoreCase(a.node.status, 'ONLINE')) {
                        return -1;
                    }
                    if (StringUtils.equalsIgnoreCase(b.node.status, 'ONLINE')) {
                        return 1;
                    }
                }
            });
            setDeviceViews(deviceViews);
        }
        loadDeviceViews();

        let refreshTask = setInterval(()=> {
            loadDeviceViews();
        }, 5000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    // render
    let getDeviceStatus = (status: string)=> {
        if (status=='ONLINE') {
            return <OnlineStatus>
                <StatusText>在线</StatusText>
                <svg className="icon" aria-hidden="true" style={{color: '#F97D5B', fontSize: '18px'}}>
                    <use xlinkHref="#icon-online"></use>
                </svg>
            </OnlineStatus>;
        } else if(status=='OFFLINE') {
            return <OfflineStatus>
                <StatusText>离线</StatusText>
                <svg className="icon" aria-hidden="true" style={{color: '#F97D5B', fontSize: '18px'}}>
                    <use xlinkHref="#icon-offline"></use>
                </svg>
            </OfflineStatus>;
        }
        return 'N/A';
    };

    let getDeviceHealth = (deviceView)=> {
        let totalOnlineTimeInHours = deviceView.node.totalOnlineTime / 3600 / 1000;
        let value = totalOnlineTimeInHours / 6000;
        return value;
    };

    let initializeListCarousel = (dom)=> {
        if (dom) {
            carouselRef.current = AutoScrollUtils.addAutoScroll(dom);
        } else {
            if (carouselRef.current) {
                AutoScrollUtils.removeAutoScroll(carouselRef.current);
            }
        }
    };

    return (
        <DeviceListWrapper>
            <Content>
                <TableHeader>
                    <TableHeaderRow>
                        <DeviceName>设备名称</DeviceName>
                        <DeviceStatus>设备状态</DeviceStatus>
                        <DeviceRssiValue>信号强度</DeviceRssiValue>
                        <DeviceHealth>健康度</DeviceHealth>
                    </TableHeaderRow>
                </TableHeader>

                <TableContent ref={(dom)=> initializeListCarousel(dom)}>
                    {
                        deviceViews.map((e)=> {
                            return <TableRow key={e.device.id} onClick={()=> onDeviceSelected(e)}>
                                <DeviceName>{e.device.name}</DeviceName>
                                <DeviceStatus>{getDeviceStatus(e.node.status)}</DeviceStatus>
                                <DeviceRssiValue><DeviceRssi deviceRssi={e.latestHeartBeatMetric.rssi} /></DeviceRssiValue>
                                <DeviceHealth style={{color: 'rgb(65, 227, 175)'}}><CountUp end={getDeviceHealth(e)} decimals={2} /></DeviceHealth>
                            </TableRow>
                        })
                    }
                </TableContent>
            </Content>
        </DeviceListWrapper>
    )
};
