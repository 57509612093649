import { useEffect, useRef, useState } from "react";
import { Content, AlarmEvents, OverviewItem, OverviewItemLabel, OverviewItemValue, AlarmsOverview, OverviewItemGroup, OverviewItemSeparator, ProcessPercent, TableHeader, TableHeaderRow, TableContent, DeviceName, EventTime, EventDetails, EventStatus, TableRow } from "./DeviceAlarmStatistics.styled";
import { DeviceAlarmsWrapper } from "./DeviceAlarmStatistics.styled";
import AlarmEventApi from "../../../../../api/AlarmEventApi";
import moment from "moment";
import AutoScrollUtils from "../../../../../util/AutoScrollUtils";

interface DeviceAlarmStatisticsProprs {

}

export const DeviceAlarmStatistics: React.FC<DeviceAlarmStatisticsProprs> = (props)=> {
    // api
    let alarmEventApi = new AlarmEventApi();
    
    // ref
    let carouselRef = useRef<any>();

    // state
    let [alarmEventStatistics, setAlarmEventStatistics] = useState({} as any);
    let [alarmEvents, setAlarmEvents] = useState([]);
    
    let initializeListCarousel = (dom)=> {
        if (dom) {
            carouselRef.current = AutoScrollUtils.addAutoScroll(dom);
        } else {
            if (carouselRef.current) {
                AutoScrollUtils.removeAutoScroll(carouselRef.current);
            }
        }
    };

    // onload
    useEffect(()=> {
        let loadAlarmEventStatistics = async ()=> {
            let alarmEventStatistics = (await alarmEventApi.getStatistics()).data.data;
            let alarmEvents = (await alarmEventApi.queryViews({
                pageNo: 1,
                pageSize: 50,
            })).data.data.elements;
            setAlarmEventStatistics(alarmEventStatistics);
            setAlarmEvents(alarmEvents);
        }
        loadAlarmEventStatistics();

        let refreshTask = setInterval(()=> {
            loadAlarmEventStatistics();
        }, 5000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    // render
    let formatEventTime = (timestamp: number | string)=> {
        timestamp = Number(timestamp);
        return moment(timestamp).format('YY/MM/DD HH:mm');
    }

    return (
        <DeviceAlarmsWrapper>
            <Content>
                <AlarmsOverview>
                    <OverviewItemGroup>
                        <OverviewItem>
                            <OverviewItemLabel>事件总数</OverviewItemLabel>
                            <OverviewItemValue style={{color: '#F97D5B'}}>{alarmEventStatistics?.overview?.total}</OverviewItemValue>
                        </OverviewItem>
                        <OverviewItemSeparator />
                        <OverviewItem>
                            <OverviewItemLabel>今日新增</OverviewItemLabel>
                            <OverviewItemValue style={{color: '#E70302'}}>{alarmEventStatistics?.overview?.today}</OverviewItemValue>
                        </OverviewItem>
                    </OverviewItemGroup>
                    <OverviewItemGroup>
                        <OverviewItem>
                            <OverviewItemLabel>未处理</OverviewItemLabel>
                            <OverviewItemValue style={{color: '#E70302'}}>
                                <span style={{paddingLeft: '10px'}}>{alarmEventStatistics?.overview?.unprocessed}</span>
                                <ProcessPercent>{alarmEventStatistics ? Math.round(alarmEventStatistics?.overview?.unprocessed / alarmEventStatistics?.overview?.total * 100) : 0}%</ProcessPercent>
                            </OverviewItemValue>
                        </OverviewItem>
                        <OverviewItemSeparator />
                        <OverviewItem>
                            <OverviewItemLabel>已处理</OverviewItemLabel>
                            <OverviewItemValue style={{color: '#1EA77B'}}>
                                <span style={{paddingLeft: '10px'}}>{alarmEventStatistics?.overview?.processed}</span>
                                <ProcessPercent>{alarmEventStatistics ? Math.round(alarmEventStatistics?.overview?.processed / alarmEventStatistics?.overview?.total * 100) : 0}%</ProcessPercent>
                            </OverviewItemValue>
                        </OverviewItem>
                    </OverviewItemGroup>
                </AlarmsOverview>

                <AlarmEvents>
                    <TableHeader>
                        <TableHeaderRow>
                            <DeviceName>设备名称</DeviceName>
                            <EventTime>事件时间</EventTime>
                            <EventDetails>事件详情</EventDetails>
                            <EventStatus>事件状态</EventStatus>
                        </TableHeaderRow>
                    </TableHeader>

                    <TableContent ref={(dom)=> initializeListCarousel(dom)}>
                        {
                            alarmEvents.map((e)=> {
                                return <TableRow key={e.event.id}>
                                    <DeviceName>{e.device.name}</DeviceName>
                                    <EventTime className="value">{formatEventTime(parseInt(e.event.eventTime || e.event.startTime))}</EventTime>
                                    <EventDetails>{e.event.details}</EventDetails>
                                    <EventStatus style={{color: '#E70302'}}>未处理</EventStatus>
                                </TableRow>
                            })
                        }
                    </TableContent>
                </AlarmEvents>
            </Content>
        </DeviceAlarmsWrapper>
    )
};
