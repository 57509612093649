import React, { useEffect, useRef } from "react";
import EventManager from "./common/event-manager/EventManager";
import Navigator from "./components/navigator/Navigator";
import { DeivceOnlineDurationWrapper, DeviceAlarmStatisticsWrapper, DeviceAlarmsWrapper, DeviceListWrapper, DeviceMapWrapper, DeviceOnlineStatisticsWrapper, DeviceTrafficStatisticsWrapper, OverviewBigScreenInnerWrapper, OverviewBigScreenWrapper, ScreenBorderBottom, ScreenBorderTop, ScreenTitleBar } from "./OverviewBigScreen.styled";
import { DeviceMap } from "./components/device-map/DeviceMap";
import { DeviceOnlineStatistics } from "./components/device-online-statistics/DeviceOnlineStatistics";
import { DeviceList } from "./components/device-list/DeviceList";
import { DeviceAlarms } from "./components/device-alarm/DeviceAlarms";
import { DeviceAlarmStatistics } from "./components/device-alarm-statistics/DeviceAlarmStatistics";
import DeviceNetworkTraffic from "./components/device-network-traffic/DeviceNetworkTraffix";
import DeviceOnlineDuration from "./components/device-online-duration/DeviceOnlineDuration";

import ScreenBorderTopBackground from "../assets/images/screen-border-top.png";
import ScreenBorderBottomBackground from "../assets/images/screen-border-bottom.png";
import BorderDecoratedCard from "./common/border-decorated-card/BorderDecoratedCard";
import { Carousel } from "antd";

interface OverviewBigScreenProps {
    
}

export const OverviewBigScreen: React.FC<OverviewBigScreenProps> = (props)=> {
    // event manager
    let eventManagerRef = useRef(new EventManager());

    // effect
    useEffect(()=> {
        let originalBackground = document.body.style.backgroundColor;
        document.body.style.backgroundColor = '#2A2A2A';
        return ()=> {
            document.body.style.backgroundColor = originalBackground;
        };
    }, []);

    // render
    return (
        <OverviewBigScreenWrapper>
            <OverviewBigScreenInnerWrapper>
                <ScreenTitleBar>
                    <Navigator selected="OVERVIEW"/>
                </ScreenTitleBar>

                <DeviceMapWrapper>
                    <DeviceMap eventManager={eventManagerRef.current}/>
                </DeviceMapWrapper>

                <ScreenBorderTop src={ScreenBorderTopBackground} />
                <ScreenBorderBottom src={ScreenBorderBottomBackground} />
                
                <DeviceOnlineStatisticsWrapper>
                    <BorderDecoratedCard
                        titleCn="设备在线情况"
                        titleEn="ONLINE STATISTICS"
                        style={{width: '100%', height: '100%'}}
                    >
                        <DeviceOnlineStatistics />
                    </BorderDecoratedCard>
                </DeviceOnlineStatisticsWrapper>

                <DeviceListWrapper>
                    <BorderDecoratedCard
                        titleCn="设备列表"
                        titleEn="DEVICE LIST"
                        style={{width: '100%', height: '100%'}}
                    >
                        <DeviceList eventManager={eventManagerRef.current}/>
                    </BorderDecoratedCard>
                </DeviceListWrapper>

                <DeivceOnlineDurationWrapper>
                    <Carousel autoplay autoplaySpeed={10000} dots={false} draggable effect="fade" style={{height: '100%', overflow: 'hidden'}}>
                        <BorderDecoratedCard
                                titleCn="设备时长统计"
                                titleEn="DURATION STATISTICS"
                                style={{width: '100%', height: '100%'}}
                            >
                            <DeviceOnlineDuration eventManager={eventManagerRef.current}/>
                        </BorderDecoratedCard>

                        <BorderDecoratedCard
                                titleCn="今日在线时长"
                                titleEn="TODAY DURATION STATISTICS"
                                style={{width: '100%', height: '100%'}}
                            >
                            <DeviceOnlineDuration eventManager={eventManagerRef.current} today={true}/>
                        </BorderDecoratedCard>
                    </Carousel>
                </DeivceOnlineDurationWrapper>

                <DeviceTrafficStatisticsWrapper>
                    <BorderDecoratedCard
                        titleCn="流量使用统计"
                        titleEn="NETWORK TRAFFIC USAGE"
                        style={{width: '100%', height: '100%'}}
                    >
                        <DeviceNetworkTraffic eventManager={eventManagerRef.current} />
                    </BorderDecoratedCard>
                </DeviceTrafficStatisticsWrapper>

                <DeviceAlarmStatisticsWrapper>
                    <BorderDecoratedCard
                            titleCn="告警事件"
                            titleEn="ALARMING EVENTS"
                            style={{width: '100%', height: '100%'}}
                        >
                        <DeviceAlarmStatistics />
                    </BorderDecoratedCard>
                </DeviceAlarmStatisticsWrapper>

                <DeviceAlarmsWrapper>
                    <DeviceAlarms />
                </DeviceAlarmsWrapper>

            </OverviewBigScreenInnerWrapper>

        </OverviewBigScreenWrapper>
    )
};
