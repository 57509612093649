import styled from "styled-components";

export const NavigatorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px 10px 20px;
    width: 100%;
    height: 100px;
`;

export const NavigatorLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 50px;
`;

export const NavigatorCenter = styled.div`
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    position: relative;
`;

export const NavigatorRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 550px;
    padding-left: 50px;
    margin-bottom: 10px;
    box-sizing: border-box;
`;

export const ScreenTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ScreenTitleCn = styled.div`
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 6px;
    color: #EFEFEF;
    background: linear-gradient(0deg, rgba(72, 93, 140, 1.0) 10%, rgba(255, 255, 255, 1.0) 30%);
    background-clip: text;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const ScreenTitleEn = styled.div`
    font-size: 12px;
    letter-spacing: 3px;
    margin-left: 5px;
    color: #EFEFEF;
    background: linear-gradient(0deg, rgba(72, 93, 140, 1.0) 10%, rgba(255, 255, 255, 1.0) 30%);
    background-clip: text;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const NavigateItems = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 40px;
    position: relative;
    top: 1px;
`;

export const NavigateItem = styled.a`
    font-size: 16px;
    letter-spacing: 5px;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.5);
    padding: 0px 10px 11px 10px;
    margin: 0px 10px;
    cursor: pointer;
    transition: all 0.5s;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    
    &.selected {
        color: rgba(255, 255, 255, 0.95);
        border-bottom: 3px solid rgba(255, 235, 59, 1);
        text-shadow: 0px 0px 3px #FFFFFF;
    }

    &:hover {
        color: rgba(255, 255, 255, 0.95);
        text-shadow: 0px 0px 5px #FFFFFF;
    }
`;

export const NavigateItemSeparator = styled.div`
    height: 18px;
    width: 1px;
    margin-bottom: 10px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.1) 100%);
`;

export const TitleBorder = styled.div`
    position: absolute;
    top: 25px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

export const BorderLeftCircle = styled.div`
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
    background-color: rgba(255, 255, 255, 0.25);
    margin: 0px 5px 0px 0px;
`;

export const BorderLeftBlock = styled.div`
    height: 3px;
    width: 30px;
    border-radius: 1.5px;
    background-color: rgba(255, 255, 255, 0.25);
`;

export const BorderLine = styled.div`
    flex: 1 1 auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    position: relative;
    dislay: flex;
    flex-direction: row;
    align-items: bottom;
`;

export const BorderRightLine = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    top: -10px;
    left: -1px;
    height: 20px;
    width: 200px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    border-left: 1px solid rgba(255, 255, 255, 0.25);
    transform: skew(-45deg);
    transform-origin: left bottom;
`;

export const BorderRightBlock = styled.div`
    position: relative;
    left: 18px;
    top: -20px;
    height: 3px;
    width: 30px;
    border-radius: 1.5px;
    background-color: rgba(255, 255, 255, 0.25);
`;

export const BorderRightCircle = styled.div`
    position: relative;
    left: 18px;
    top: -20px;
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0px 0px 0px 5px;
`;

export const Content = styled.div`
    flex: 1 1 auto;
`;


