import { CSSProperties } from "styled-components";
import { Star, StarsBackgroundWrapper } from "./StarsBackground.styled";

interface StarsBackgroundProps {
    rows: number,
    cols: number,
    style?: CSSProperties,
}

let StarsBackground: React.FC<StarsBackgroundProps> = (props)=> {
    let indexes = [];
    let {rows, cols} = props;
    for(let i=0; i<rows; i++) {
        for(let j=0; j<cols; j++) {
            let opacity = 1 - Math.sqrt(
                Math.pow(Math.abs((i+0.5)-rows/2)/(rows/2 + 1), 2) 
                * Math.pow(Math.abs((j+0.5)-cols/2)/(cols/2 + 1), 2)
            );
            indexes.push({seq: i*cols+j, row: i, col: j, opacity: opacity});
        }
    }

    return <StarsBackgroundWrapper style={{gridTemplateRows: `repeat(${props.rows}, 1fr)`, gridTemplateColumns: `repeat(${props.cols}, 1fr)`, ...props.style}}>
        {
            (indexes).map((e)=> {
                return <Star key={e.seq} style={{opacity: e.opacity}} />
            })
        }
    </StarsBackgroundWrapper>
}

export default StarsBackground;
