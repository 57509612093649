import styled from "styled-components";

export const StarsBackgroundWrapper = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
`;

export const Star = styled.div`
    background: rgba(255, 255, 255, 0.1);
    width: 1px;
    height: 1px;
    border-radius: 1.5px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
    z-index: 99;
`;



