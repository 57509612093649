import { useEffect, useState } from "react";
import FirmwareApi from "../../api/FirmwareApi";
import { Button, Input, Modal, Popconfirm, Space, Table, TableColumnsType, message } from "antd";
import { FirmwareManagementWrapper, TableWrapper, ToolBarLeftWrapper, ToolBarRightWrapper, ToolBarWrapper } from "./FirmwareManagement.styled";
import { SearchOutlined } from "@ant-design/icons";
import CreateFirmwareWizard from "./components/create-firmware/CreateFirmwareWizard";
import StringUtils from "../../util/StringUtils";
import CreateFirmwarePushTaskWizard from "../firmware-push-managment/create-push-task/CreateFirmwarePushTaskWizard";
import FirmwarePushTaskDetails from "../firmware-push-managment/view-task/FirmwarePushTaskDetails";

interface FirmwareManagement {

}

interface ProductFirmwareView {
    productModel: string;
    latestVersion: string;
    deviceCount: number;
    firmwares: FirmwareView[];
}

interface FirmwareView {
    id: string,
    productModel: string;
    version: string;
    remarks: string;
    size: number;
    md5: string;
    deviceCount: number;
}

const FirmwareManagement: React.FC<FirmwareManagement> = (props)=> {

    // api
    let firmwareApi = new FirmwareApi();
    const [messageApi, contextHolder] = message.useMessage();
    
    // state
    let [loading, setLoading] = useState(true);
    let [openCreateFirmwareModal, setOpenCreateFirmwareModal] = useState(false);
    let [openCreateFirmwarePushTaskModal, setOpenCreateFirmwarePushTaskModal] = useState(false);
    let [openFirmwarePushTaskDetailsModal, setOpenFirmwarePushTaskDetailsModal] = useState(false);
    let [firmwareViews, setFirmwareViews] = useState([]);
    let [firmwareToPush, setFirmwareToPush] = useState(null);
    let [firmwarePushTaskId, setFirmwarePushTaskId] = useState(null);

    // functions
    let loadFirmwareViews = async ()=> {
        setLoading(true);
        let firmwareViews = (await firmwareApi.getAllFirmwareViews()).data.data;
        for(let firmwareView of firmwareViews) {
            firmwareView.children =firmwareView.firmwares;
        }
        setFirmwareViews(firmwareViews);
        setLoading(false);
    };

    // event
    let onAddFirmwareClick = ()=> {
        setOpenCreateFirmwareModal(true);
    };

    let onPushFirmwareClick = (firmwareView)=> {
        setOpenCreateFirmwarePushTaskModal(true);
        setFirmwareToPush(firmwareView);
    };

    let onDeleteFirmware = async (firmwareView)=> {
        await firmwareApi.delete(firmwareView.id);
        messageApi.open({
            type: 'success',
            content: '删除固件成功',
        });
        loadFirmwareViews();
    };

    // effect
    useEffect(()=> {
        loadFirmwareViews();
    }, []);

    // render
    const firmwareColumns: TableColumnsType<any> = [
        {
            title: '产品型号',
            dataIndex: 'productModel',
            key: 'productModel',
            width: '150px',
        },
        {
            title: '最新版本号/版本号',
            dataIndex: 'version',
            key: 'version',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record.latestVersion || record.version}</span>
            }
        },
        {
            title: '固件大小 (KB)',
            dataIndex: 'size',
            key: 'size',
            align: 'center',
            render: (text, record, index)=> {
                if (record.size == null) {
                    return StringUtils.emptyToSlash('');
                }
                return (record.size / 1024).toFixed(1) + ' KB'
            }
        },
        {
            title: 'MD5校验',
            dataIndex: 'md5',
            key: 'md5',
            align: 'center',
            render: (text, record, index)=> {
                if (record.md5 == null) {
                    return StringUtils.emptyToSlash('');
                }
                return record.md5;
            }
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '设备数',
            dataIndex: 'deviceCount',
            key: 'deviceCount',
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, index)=> {
                return record.id && <Space size="middle">
                    <a onClick={()=> onPushFirmwareClick(record)}>推送固件</a>
                    <Popconfirm
                            title="删除固件"
                            description={ <div style={{paddingTop: "10px", paddingBottom: '10px'}}>此操作将删除固件，确认要删除么?</div> }
                            onConfirm={()=> onDeleteFirmware(record)}
                            okText="确定"
                            cancelText="取消"
                        > 
                        <a>删除固件</a>
                    </Popconfirm>
                </Space>
            }
        }
    ];

    return (
        <FirmwareManagementWrapper>
            {contextHolder}
            <ToolBarWrapper>
                <ToolBarLeftWrapper>
                    <Space size="middle">
                        <Button type="primary" onClick={onAddFirmwareClick}>新增固件</Button>
                    </Space>
                </ToolBarLeftWrapper>

                <ToolBarRightWrapper>
                    <Input placeholder="请输入关键字" suffix={<SearchOutlined />} size="large" />
                </ToolBarRightWrapper>

                <Modal open={openCreateFirmwareModal} 
                    onClose={()=> setOpenCreateFirmwareModal(false)} 
                    footer={null} 
                    closable={false}
                    destroyOnClose={true}
                    width={1000}
                    >
                    <CreateFirmwareWizard 
                        onClose={()=> setOpenCreateFirmwareModal(false)} 
                        onComplete={()=> loadFirmwareViews()}/>
                </Modal>

                <Modal open={openCreateFirmwarePushTaskModal} 
                    onClose={()=> setOpenCreateFirmwarePushTaskModal(false)} 
                    footer={null} 
                    closable={false}
                    width={1440}
                    height={800}
                    >
                    <CreateFirmwarePushTaskWizard 
                        targetFirmware={firmwareToPush}
                        onClose={()=> setOpenCreateFirmwarePushTaskModal(false)} 
                        onComplete={(firmwarePushTask)=> {setFirmwarePushTaskId(firmwarePushTask.id)}}
                        />
                </Modal>

                <Modal open={openFirmwarePushTaskDetailsModal} 
                    footer={null} 
                    closable={false}
                    width={1440}
                    height={800}
                    onClose={()=> setOpenFirmwarePushTaskDetailsModal(false)} 
                    >
                    <FirmwarePushTaskDetails 
                        id={firmwarePushTaskId}
                        onClose={()=> setOpenFirmwarePushTaskDetailsModal(false)}
                        />
                </Modal>
                
            </ToolBarWrapper>

            <TableWrapper>
                <Table 
                    rowKey={(record)=> record.productModel + record.version}
                    columns={firmwareColumns} 
                    dataSource={firmwareViews}
                />
            </TableWrapper>
        </FirmwareManagementWrapper>
    );
};

export default FirmwareManagement;
