import { useEffect, useRef, useState } from "react";
import { Progress, Statistic } from "antd";
import * as echarts from "echarts";
import { ECharts } from "echarts";
import { CircleElementsBackgroundImage, Content, DeviceOnlineStatisticsWrapper, EChartHighlighData, EChartWrapper, EchartOuterWrapper, StatisticDataName, StatisticDataValue, StatisticsItem, StatisticsItemLabel, StatisticsItemTextWrapper, StatisticsItemValue, StatisticsOverview, TechnicalLine } from "./DeviceOnlineStatistics.styled";
import DashboardApi from "../../../../../api/DashboardApi";
import StarsBackground from "../../common/starts-background/StarsBackground";
import CountUp from "react-countup";

interface DeviceStatisticsItemProps {
    text: string,
    value: number,
    total: number,
    color: string,
}

const DeviceStatisticsItem: React.FC<DeviceStatisticsItemProps> = (props)=> {
    return (
        <StatisticsItem>
            <StatisticsItemTextWrapper>
                <StatisticsItemLabel>{props.text} [占比: <CountUp end={Math.round(props.value/props.total*100)} />%]</StatisticsItemLabel>
                <StatisticsItemValue style={{color: props.color, textShadow: `0px 0px 1px ${props.color}`}}><CountUp end={props.value ?? 0} /></StatisticsItemValue>
            </StatisticsItemTextWrapper>
            <Progress 
                size="small"
                showInfo={false}
                strokeColor={props.color}
                steps={50}
                percent={Math.round(props.value / props.total * 100.0)} 
            />
        </StatisticsItem>
    )
};

interface DeviceOnlineStatisticsProprs {

}

export const DeviceOnlineStatistics: React.FC<DeviceOnlineStatisticsProprs> = (props)=> {
    let dashboardApi = new DashboardApi();

    // ref
    let echartsDomRef = useRef<HTMLElement>();
    let echartsRef = useRef<ECharts>(null);
    let systemOverviewRef = useRef(null);
    let countUpEndRef = useRef(0);
        
    // state
    let [systemOverview, setSystemOverview] = useState(null);
    let [highlightIndex, setHighlightIndex] = useState(0);
    let [countUpStart, setCountUpStart] = useState(0);
    let [countUpEnd, setCountUpEnd] = useState(0);
    
    // init ECharts
    let initECharts = (dom: HTMLElement)=> {
        let echartsInstance = echarts.init(dom);
        echartsRef.current = echartsInstance;

        // init options
        let option = {
            tooltip: {
                show: false,
                trigger: 'item'
            },
            legend: {
                show: false,
            },
            grid: {
                x: 10,
                y: 10,
                x2: 10,
                y2: 10,
            },
            series: [
                {
                    // name: 'OUTER CIRCLE',
                    name: '',
                    type: 'pie',
                    radius: ['70%', '75%'],
                    startAngle: 45,
                    padAngle: 2,
                    itemStyle: {
                        borderWidth: 2,
                        borderColor: 'transparent',
                    },
                    label: {
                        show: false,
                        position: 'center',
                        textBorderColor: 'transparent',
                        formatter: '{value|  {d}%} \n{name|{b}}',
                        rich: {
                            value: {
                                fontFamily: 'Rajdhani',
                                fontWeight: 'bold',
                                fontSize: '20',
                                color: '#FFFFFF',
                                padding: [0, 0, 2, 0],
                            },
                            name: {
                                fontWeight: 'normal',
                                fontStyle: 'italic',
                                fontSize: '14',
                                color: '#FFFFFF',
                            }
                        }
                    },
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: 18,
                        }
                    },
                    labelLine: {
                        show: false,
                    },
                    data: [],
                },
                {
                    // name: 'INNER CIRCLE',
                    name: '',
                    type: 'pie',
                    radius: ['45%', '70%'],
                    startAngle: 45,
                    padAngle: 2,
                    hoverAnimation: false,
                    itemStyle: {
                        borderWidth: 2,
                        borderColor: 'transparent',
                    },
                    label: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: 18,
                        }
                    },
                    labelLine: {
                        show: false,
                    },
                    data: [],
                }
            ]
        };
        echartsInstance.setOption(option);
    }

    // onload
    useEffect(()=> {
        let loadSystemOverview = async ()=> {
            let systemRealtimeOverview = (await dashboardApi.getSystemRealtimeOverview()).data.data;
            setSystemOverview(systemRealtimeOverview);
            systemOverviewRef.current = systemRealtimeOverview;
            
            // refresh echarts options
            echartsRef.current?.setOption({
                series: [
                    {
                        data: [
                            {
                                name: '在线', 
                                value: systemRealtimeOverview.deviceOverview.online, 
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(30, 167, 123, 1.0)',
                                    }
                                }, 
                            },
                            {
                                name: '离线', 
                                value: systemRealtimeOverview.deviceOverview.offline, 
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(249, 125, 91, 1.0)',
                                    }
                                }, 
                            }
                        ]
                    }, {
                        data: [
                            {
                                name: '在线', 
                                value: systemRealtimeOverview.deviceOverview.online, 
                                itemStyle: {
                                    normal: {
                                        // color: 'rgba(30, 167, 123, 0.3)',
                                        color: new echarts.graphic.RadialGradient(0.5, 0.5, 0.75, [
                                            {
                                              offset: 0,
                                              color: 'rgba(30, 167, 123, 0.0)',
                                            },
                                            {
                                              offset: 1,
                                              color: 'rgba(30, 167, 123, 0.3)',
                                            }
                                        ])
                                    }
                                }, 
                            },
                            {
                                name: '离线', 
                                value: systemRealtimeOverview.deviceOverview.offline, 
                                itemStyle: {
                                    normal: {
                                        // color: 'rgba(249, 125, 91, 0.3)',
                                        color: new echarts.graphic.RadialGradient(0.5, 0.5, 0.75, [
                                            {
                                              offset: 0,
                                              color: 'rgba(249, 125, 91, 0.0)',
                                            },
                                            {
                                              offset: 1,
                                              color: 'rgba(249, 125, 91, 0.3)',
                                            }
                                        ])
                                    }
                                }, 
                            }
                        ]
                    }
                ]
            });
        };
        loadSystemOverview();

        let refreshTask = setInterval(()=> {
            loadSystemOverview();
        }, 5000);

        let highlightIndex = 0;
        let highlightAnimation = ()=> {
            if (echartsRef.current) {
                let echarts = echartsRef.current;
                echarts.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: highlightIndex,
                });
                highlightIndex++;
                highlightIndex%=2;
                echarts.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: highlightIndex,
                });
                setHighlightIndex(highlightIndex);

                setCountUpStart(countUpEndRef.current);
                setCountUpEnd(getHighlightPercent(highlightIndex));
                countUpEndRef.current=getHighlightPercent(highlightIndex);
            }
        }

        let highlighTask = setInterval(()=> {
            highlightAnimation();
        }, 3000);
        
        if (echartsDomRef.current!=null) {
            initECharts(echartsDomRef.current);
        }

        return ()=> {
            clearInterval(refreshTask);
            clearInterval(highlighTask);
            if (echartsRef.current) {
                echartsRef.current.dispose();
            }
        }
    }, []);

    let getHighlightPercent = (highlightIndex: number)=> {
        systemOverview = systemOverviewRef.current;
        if (systemOverview == null) {
            return 0;
        }
        
        let value = 0;
        switch(highlightIndex) {
            case 0:
                value = systemOverview.deviceOverview.online;
                break;
            case 1:
                value = systemOverview.deviceOverview.offline;
                break;
        }

        let total = systemOverview.deviceOverview.total;
        return Math.round(value / total * 100);
    };
    
    let getHighlightLabel = (highlightIndex: number)=> {
        switch(highlightIndex) {
            case 0:
                return '在线';
            case 1:
                return '离线';
            case 2:
                return '告警';
        }
        return '';
    };

    // render
    return (
        <DeviceOnlineStatisticsWrapper>
            <Content>
                <EchartOuterWrapper>
                    <CircleElementsBackgroundImage />
                    <EChartHighlighData>
                        <StatisticDataValue>
                            <CountUp start={countUpStart} end={countUpEnd} />
                            <span style={{fontSize: '12px'}}>%</span>
                        </StatisticDataValue>
                        <StatisticDataName>{getHighlightLabel(highlightIndex)}</StatisticDataName>
                    </EChartHighlighData>
                    <EChartWrapper ref={(dom)=> {echartsDomRef.current=dom}}>
                    </EChartWrapper>
                </EchartOuterWrapper>

                <TechnicalLine style={{top: '0px', left: '75px'}}/>

                <StarsBackground 
                    rows={20} 
                    cols={35} 
                    style={{
                        position: 'absolute', 
                        top: '12px', 
                        left: '3px',
                        width: '100%',
                        height: '100%',
                    }} />

                <StatisticsOverview>
                    <DeviceStatisticsItem
                        text="设备总数"
                        value={systemOverview?.deviceOverview?.total ?? 0}
                        total={systemOverview?.deviceOverview?.total ?? 0}
                        color="#546FC6"
                    />
                    
                    <DeviceStatisticsItem
                        text="在线设备"
                        value={systemOverview?.deviceOverview?.online ?? 0}
                        total={systemOverview?.deviceOverview?.total ?? 0}
                        color="#1EA77B"
                    />
                    
                    <DeviceStatisticsItem
                        text="离线设备"
                        value={systemOverview?.deviceOverview?.offline ?? 0}
                        total={systemOverview?.deviceOverview?.total ?? 0}
                        color="#F97D5B"
                    />

                    <DeviceStatisticsItem
                        text="告警设备"
                        value={systemOverview?.deviceOverview?.warning ?? 0}
                        total={systemOverview?.deviceOverview?.total ?? 0}
                        color="#E70302"
                    />
                </StatisticsOverview>
            </Content>
        </DeviceOnlineStatisticsWrapper>
    )
};
