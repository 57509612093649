import { ReactNode } from "react";
import { Content, BorderDecoratedCardWrapper, Title, TitleCn, TitleEn } from "./BorderDecoratedCard.styled";
import { CSSProperties } from "styled-components";

interface BorderDecoratedCardProps {
    titleCn: string,
    titleEn: string,
    style?: CSSProperties,
    children?: ReactNode[] | ReactNode,
}

let BorderDecoratedCard: React.FC<BorderDecoratedCardProps> = (props)=> {

    return <BorderDecoratedCardWrapper style={props.style}>
        <Title>
            <TitleCn>{props.titleCn}</TitleCn>
            <TitleEn>{props.titleEn}</TitleEn>
        </Title>

        <Content>
            {props.children}
        </Content>
    </BorderDecoratedCardWrapper>
};

export default BorderDecoratedCard;

