import styled from "styled-components";

export const WeatherWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
`;

export const LocationIcon = styled.div`
    font-size: 18px;
    padding: 0px 5px;
    white-space: nowrap;
`;

export const City = styled.div`
    font-size: 16px;
    padding: 0px 10px;
    white-space: nowrap;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
`;

export const WeatherIcon = styled.div`
    font-size: 42px;
    margin-bottom: 3px;
    padding: 0px 0px 0px 10px;
`;

export const Temperature = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    padding: 0px 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
`;

export const TemperatureUnit = styled.div`
    font-size: 18px;
`;
