import { useEffect, useRef, useState } from "react";
import { DeviceRealtimeMetricsWrapper, MetricsUpdateTime, TableContent, TableHeader, TableHeaderRow, TableRow, Title, VariableName, VariableUnit, VariableValue } from "./DeviceRealtimeMetrics.styled";
import DeviceApi from "../../../../../api/DeviceApi";
import DeviceMetricApi from "../../../../../api/DeviceMetricApi";
import StringUtils from "../../../../../util/StringUtils";
import TimeUtils from "../../../../../util/TimeUtils";
import EventManager from "../../common/event-manager/EventManager";

interface DeviceRealtimeMetricsProps {
    eventManager: EventManager,
    deviceId: string
}

let DeviceRealtimeMetrics: React.FC<DeviceRealtimeMetricsProps> = (props)=> {

    // api
    let deviceApi = new DeviceApi();
    let deviceMetricApi = new DeviceMetricApi();

    // state
    let [deviceView, setDeviceView] = useState<any>(null);
    let [deviceTemplateProperties, setDeviceTemplateProperties] = useState([]);
    let [latestDeviceMetrics, setLatestDeviceMetrics] = useState<any>({});

    // unwrap parameters

    // functions
    let fetchLatestDeviceMetrics = async (deviceId: string)=> {
        let latestDeviceMetrics = (await deviceMetricApi.getLatestDeviceMetricsView(deviceId)).data.data;
        setLatestDeviceMetrics(latestDeviceMetrics);
        props.eventManager.notifyLatestDeviceUpdated(latestDeviceMetrics);
    };

    let fetchDeviceView = async (deviceId: string)=> {
        // load device views
        let deviceView = (await deviceApi.getDeviceViewByDeviceId(deviceId)).data.data;
        setDeviceView(deviceView);

        // parse template properties
        let deviceTemplate = deviceView.deviceTemplate;
        let configuration = JSON.parse(StringUtils.emptyToDefault(deviceTemplate.configuration, "{}"));
        let properties = configuration.properties;
        setDeviceTemplateProperties(properties);

        // fetch metrics
        await fetchLatestDeviceMetrics(deviceId);
    };

    // effect
    useEffect(()=> {
        fetchDeviceView(props.deviceId);

        let refreshTask = window.setInterval(()=> {
            fetchLatestDeviceMetrics(props.deviceId);
        }, 5000);
        
        // unload
        return ()=> {
            window.clearInterval(refreshTask);
        }
    }, []);

    // render

    let formatMetricsValue = (latestDeviceMetrics: any, deviceTemplateProperty: any)=> {
        let value = latestDeviceMetrics[deviceTemplateProperty.key]?.value;
        if (value == null) {
            value = 0;
        } else {
            value = parseFloat(value);
        }
        let text = value.toFixed(deviceTemplateProperty.scale);
        return text;
    };

    return <DeviceRealtimeMetricsWrapper>
        <TableHeader>
            <TableHeaderRow>
                <VariableName>变量名称</VariableName>
                <VariableValue>变量值</VariableValue>
                <VariableUnit>单位</VariableUnit>
                <MetricsUpdateTime>更新时间</MetricsUpdateTime>
            </TableHeaderRow>
        </TableHeader>

        <TableContent>
            {
                deviceTemplateProperties.map((e)=> {
                    return <TableRow key={e.name} onClick={()=> props.eventManager.notifyDeviceMetricSelected(e, latestDeviceMetrics[e.key])}>
                        <VariableName>{e.name}</VariableName>
                        <VariableValue>{formatMetricsValue(latestDeviceMetrics, e)}</VariableValue>
                        <VariableUnit>{StringUtils.emptyToSlash(e.unit)}</VariableUnit>
                        <MetricsUpdateTime><span style={{textAlign: 'center'}}>{TimeUtils.formatTimestamp(latestDeviceMetrics[e.key]?.timestamp, "YY/MM/DD HH:mm:ss")}</span></MetricsUpdateTime>
                    </TableRow>
                })
            }
        </TableContent>
    </DeviceRealtimeMetricsWrapper>
};

export default DeviceRealtimeMetrics;