import axios, { AxiosResponse } from "axios";
import AxiosService from "./Axios";

class SimCardTrafficApi {
    
    public async getLatestSimCardTrafficPools(): Promise<AxiosResponse<any>> {
        return AxiosService.get('/sim-card-traffic/pool/latest');
    }
}

export default SimCardTrafficApi;