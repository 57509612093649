class Menu {
    groups: MenuGroup[];

    public static getMainMenu(): Menu {
        let overviewGroup = {
            name: '系统概览',
            items: [
                {
                    name: '实时概览',
                    path: '/dashboard',
                },
                {
                    name: '数据大屏',
                    path: '/big-screen/overview',
                },
                {
                    name: '自定义大屏',
                    path: '/big-screen-management',
                },
            ],
        };

        let deviceGroup = {
            name: '设备管理',
            items: [
                {
                    name: '设备列表',
                    path: '/device-management',
                },
                {
                    name: '设备分组',
                    path: '/device-group-management',
                },
                {
                    name: '设备模板',
                    path: '/device-template-management',
                },
                {
                    name: '产品管理',
                    path: '/product-management',
                },
            ],
        };

        let alarmGroup = {
            name: '告警管理',
            items: [
                {
                    name: '告警事件',
                    path: 'alarm-events',
                },
                {
                    name: '告警规则',
                    path: 'alarm-rules',
                }
            ],
        };

        let dataGroup = {
            name: '数据中心',
            items: [
                {
                    name: '统计报表',
                    path: '/statistics',
                },
                {
                    name: '设备上下线记录',
                    path: '/device-online-offline-history',
                },
                {
                    name: '设备历史数据',
                    path: '/device-metrics-management',
                }
            ],
        };

        let otaGroup = {
            name: 'OTA',
            items: [
                {
                    name: '固件管理',
                    path: '/firmware-management',
                },
                {
                    name: '固件推送',
                    path: '/firmware-push-management',
                }
            ],
        };

        let systemSettingsGroup = {
            name: '系统设置',
            items: [
                {
                    name: '用户管理',
                    path: '/user-management'
                },
                {
                    name: '角色管理',
                    path: '/role-management',
                }
            ],
        };

        return {
            groups: [
                overviewGroup,
                deviceGroup,
                alarmGroup,
                dataGroup,
                otaGroup,
                systemSettingsGroup,
            ],
        }
    }
}

class MenuGroup {
    name: string;
    items: MenuItem[];
}

class MenuItem {
    name: string;
    path: string;
}

export default Menu;
export { Menu, MenuGroup, MenuItem};
