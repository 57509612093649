import { Button, Progress, Space, Tag } from "antd";
import FirmwarePushTaskApi from "../../../api/FirmwarePushTaskApi";
import { Content, FirmwareModelVersion, FirmwarePushTaskDetailsWrapper, InfoGroupSeparator, InfoItem, InfoItemRow, InfoLabel, InfoValue, NodeDeviceName, NodeOtaProgress, NodeOtaStatus, NodeProgressList, NodeProgressListHeader, NodeProgressListItem, NodeSN, OperationWrapper, ProductModel, TaskOverview, TaskOverviewLeft, TaskOverviewRight, TaskStatus, Title, TitleWrapper, Version } from "./FirmwarePushTaskDetails.styled";
import { useEffect, useRef, useState } from "react";
import StringUtils from "../../../util/StringUtils";
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, SyncOutlined, WarningOutlined } from "@ant-design/icons";
import moment from "moment";
import FirmwarePushTaskUtils from "../util/FirmwarePushTaskUtils";

interface FirmwarePushTaskDetailsProps {
    id: string,
    onClose: ()=> void
}

const FirmwarePushTaskDetails: React.FC<FirmwarePushTaskDetailsProps> = (props)=> {
    // api
    let firmwarePushTaskApi = new FirmwarePushTaskApi();

    // state
    let firmwarePushTaskViewRef = useRef(null);
    let [firmwarePushTaskView, setFirmwarePushTaskView] = useState({} as any);

    // for simplicity
    let firmwarePushTask = firmwarePushTaskView.task ?? {};
    let firmware = firmwarePushTaskView.firmware ?? {};
    let targetedNodeViews = firmwarePushTaskView.targetedNodeViews ?? [];
    
    // function
    let loadFirmwarePushTaskView = async ()=> {
        let firmwarePushTaskView = (await firmwarePushTaskApi.getTaskViewById({id: props.id})).data.data;
        setFirmwarePushTaskView(firmwarePushTaskView);
        firmwarePushTaskViewRef.current=firmwarePushTaskView;
    };

    // effect
    useEffect(()=> {
        loadFirmwarePushTaskView();
        let refreshTask = window.setInterval(()=> {
            if (FirmwarePushTaskUtils.taskStatusName2Ordinal(firmwarePushTaskViewRef.current?.status) < FirmwarePushTaskUtils.taskStatusName2Ordinal('DONE')) {
                loadFirmwarePushTaskView();
            }
        }, 3000);

        return ()=> {
            window.clearInterval(refreshTask);
        }
    }, []);

    // render
    let getFirmwareTaskStatusElement = (firmwarePushTask)=> {
        if (firmwarePushTask.status=='DONE') {
            return <Tag icon={<CheckCircleOutlined />} color="success">已完成</Tag>;
        } else if(firmwarePushTask.status=='DONE_WITH_ERROR') {
            return <Tag icon={<WarningOutlined />} color="warning">任务完成（有失败设备）</Tag>
        } else if(firmwarePushTask.status=='TIMEOUT') {
            if (getSuccessPercent(firmwarePushTask) > 0) {
                return <Tag icon={<ExclamationCircleOutlined />} color="warning">任务超时</Tag>;
            } else {
                return <Tag icon={<CloseCircleOutlined />} color="warning">任务超时</Tag>;
            }
        } else if(firmwarePushTask.status) {
            return <Tag icon={<SyncOutlined spin />} color="processing">任务执行中</Tag>
        }
    };

    let getFirmwareTaskCreatedTime = (firmwarePushTask)=> {
        let time = firmwarePushTask.createdTime;
        if (time==null) {
            return '';
        }
        return moment(Number(time)).format('YYYY/MM/DD HH:mm:ss');
    }

    let getFirmwareSize = (firmware)=> {
        if (firmware == null) {
            return '';
        }
        let sizeKB = (firmware.size / 1024).toFixed(2) + ' KB';
        return sizeKB;
    }

    let getProgressPercent = (firmwarePushTask)=> {
        if (firmwarePushTask==null) {
            return 0;
        }
        
        let total = 0;
        let complete = 0;
        for(let nodeId in firmwarePushTask.nodeOtaStatusMap) {
            let nodeOtaStatus = firmwarePushTask.nodeOtaStatusMap[nodeId];
            if (nodeOtaStatus=='DONE' || nodeOtaStatus=='ERROR') {
                complete++;
            }
            total++;
        }
        return complete / total * 100.0;
    };

    let getSuccessPercent = (firmwarePushTask)=> {
        if (firmwarePushTask==null) {
            return 0;
        }
        
        let total = 0;
        let success = 0;
        for(let nodeId in firmwarePushTask.nodeOtaStatusMap) {
            let nodeOtaStatus = firmwarePushTask.nodeOtaStatusMap[nodeId];
            if (nodeOtaStatus=='DONE') {
                success++;
            }
            total++;
        }
        return success / total * 100.0;
    };

    let getNodeProgressPercent = (nodeId) => {
        if(firmwarePushTask==null) {
            return 0;
        }
        let nodeOtaStatus = firmwarePushTask.nodeOtaStatusMap[nodeId];
        if (nodeOtaStatus == null) {
            return 0;
        }

        let nodeOtaStatus2Percent = {
            'PENDING': '1',
            'RECEIVED_OTA_MESSAGE': '2',
            'DOWNLOADING_FIRMWARE': '3',
            'REBOOT': '4',
            'DONE': '5',
            'ERROR': '5',
        };

        let percent = nodeOtaStatus2Percent[nodeOtaStatus];
        percent = percent == null ? 0 : percent / 5.0 * 100.0;
        return percent;
    };
    
    let getNodeProgressStatus = (nodeId) => {
        if(firmwarePushTask==null) {
            return 0;
        }
        let nodeOtaStatus = firmwarePushTask.nodeOtaStatusMap[nodeId];
        if (nodeOtaStatus == null) {
            return 0;
        }
        if (nodeOtaStatus == 'ERROR') {
            return 'exception';
        }
        if (nodeOtaStatus == 'DONE') {
            return 'success';
        }
        return 'active';
    };

    let getNodeOtaStatus = (nodeId) => {
        if(firmwarePushTask==null) {
            return '';
        }
        let nodeOtaStatus = firmwarePushTask.nodeOtaStatusMap[nodeId];
        if (nodeOtaStatus == null) {
            return '';
        }
        return FirmwarePushTaskUtils.nodeOtaStatus2Text(nodeOtaStatus);
    };

    return (
        <FirmwarePushTaskDetailsWrapper>
            <TitleWrapper>
                <Title>固件推送任务</Title>
            </TitleWrapper>

            { firmwarePushTaskView.task && <Content>
                <TaskOverview>
                    <TaskOverviewLeft>
                        <FirmwareModelVersion>
                            <ProductModel>{firmware.productModel}</ProductModel>
                            <Version> Ver {firmware.version}</Version>
                        </FirmwareModelVersion>
                    </TaskOverviewLeft>
                    <TaskOverviewRight>
                        <TaskStatus>{getFirmwareTaskStatusElement(firmwarePushTask)}</TaskStatus>
                    </TaskOverviewRight>
                </TaskOverview>

                <InfoItemRow>
                    <InfoItem>
                        <InfoLabel>创建时间</InfoLabel>
                        <InfoValue>{getFirmwareTaskCreatedTime(firmwarePushTask)}</InfoValue>
                    </InfoItem>

                    <InfoItem>
                        <InfoLabel>创建人</InfoLabel>
                        <InfoValue>{StringUtils.emptyToSlash(firmwarePushTask.creator)}</InfoValue>
                    </InfoItem>
                </InfoItemRow>

                
                <InfoItemRow>
                    <InfoItem>
                        <InfoLabel>固件大小</InfoLabel>
                        <InfoValue>{getFirmwareSize(firmware)}</InfoValue>
                    </InfoItem>

                    <InfoItem>
                        <InfoLabel>固件MD5</InfoLabel>
                        <InfoValue>{firmware?.md5}</InfoValue>
                    </InfoItem>
                </InfoItemRow>

                <InfoItemRow>
                    <InfoItem>
                        <InfoLabel>任务进度</InfoLabel>
                        <InfoValue>
                            <Progress
                                percent={getProgressPercent(firmwarePushTaskView?.task)}
                                strokeColor={'rgb(249, 125, 91)'}
                                success={{percent: getSuccessPercent(firmwarePushTaskView?.task), strokeColor: '#389E0E'}}
                                showInfo={false}
                            />
                        </InfoValue>
                    </InfoItem>
                </InfoItemRow>

                <InfoGroupSeparator />

                <NodeProgressList>
                    <NodeProgressListHeader>
                        <NodeSN>SN</NodeSN>
                        <NodeDeviceName>设备名称</NodeDeviceName>
                        <NodeOtaProgress>设备OTA进度</NodeOtaProgress>
                        <NodeOtaStatus>OTA状态</NodeOtaStatus>
                    </NodeProgressListHeader>
                    {
                        targetedNodeViews?.map(nodeView=> {
                            return <NodeProgressListItem key={nodeView.node.sn}>
                                <NodeSN>{nodeView.node.sn}</NodeSN>
                                <NodeDeviceName>{StringUtils.emptyToSlash(nodeView.device?.name)}</NodeDeviceName>
                                <NodeOtaProgress>
                                    <Progress 
                                        percent={getNodeProgressPercent(nodeView.node.id)} 
                                        status={getNodeProgressStatus(nodeView.node.id) as any} 
                                        showInfo={false}
                                    />
                                </NodeOtaProgress>
                                <NodeOtaStatus>
                                    {getNodeOtaStatus(nodeView.node.id)}
                                </NodeOtaStatus>
                            </NodeProgressListItem>
                        })
                    }
                </NodeProgressList>
            </Content> }

            <OperationWrapper>
                <Space size="middle">
                    <Button type="primary" onClick={props.onClose}>确定</Button>
                </Space>
            </OperationWrapper>
        </FirmwarePushTaskDetailsWrapper>
    )
}

export default FirmwarePushTaskDetails;