import { BorderLeftBlock, BorderLeftCircle, BorderLine, BorderRightLine, BorderRightCircle, NavigateItem, NavigateItems, NavigatorCenter, NavigatorLeft, NavigatorRight, NavigatorWrapper, ScreenTitle, ScreenTitleCn, ScreenTitleEn, TitleBorder, BorderRightBlock, NavigateItemSeparator } from "./Navigator.styled";

import WinchIllustration from '../../../../assets/images/winch-illustration.png';
import { Weather } from "../weather/Weather";
import { Clock } from "../clock/Clock";
import SessionService from "../../../../../services/session/SessionService";

interface NavigatorProps {
    selected?: string
}

let Navigator: React.FC<NavigatorProps> = (props)=> {
    return (
        <NavigatorWrapper>
            <NavigatorLeft>
                <NavigateItems>
                    <NavigateItem href={SessionService.getInstance().getCurrentUserProfile().admin ? "/#/" : '#'}>管理控制台</NavigateItem>
                    <NavigateItemSeparator />
                    <NavigateItem href="/#/big-screen/overview" className={props.selected=='OVERVIEW' ? "selected" : "" }>系统总览</NavigateItem>
                    <NavigateItemSeparator />
                    <NavigateItem className={props.selected=='DEVICE_DETAILS' ? "selected" : "" }>设备详情</NavigateItem>
                </NavigateItems>
            </NavigatorLeft>

            <NavigatorCenter>
                <ScreenTitle>
                    <ScreenTitleCn>智能卷扬机互联系统</ScreenTitleCn>
                    <ScreenTitleEn>Winch Intelligent Link System</ScreenTitleEn>
                </ScreenTitle>
            </NavigatorCenter>

            <NavigatorRight>
                <Weather />
                <Clock />
            </NavigatorRight>
        </NavigatorWrapper>
    )
};

export default Navigator;
