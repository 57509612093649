import React, { useEffect, useState } from "react";
import { DeviceRssiIcon, DeviceRssiText, DeviceRssiWrapper } from "./DeviceRssi.styled";
import CountUp from "react-countup";

interface DeviceRssiProps {
    deviceRssi: number
}

let DeviceRssi: React.FC<DeviceRssiProps> = (props)=> {
    // state
    let [countUpStart, setCountUpStart] = useState(0);
    let [countUpEnd, setCountUpEnd] = useState(0);
    
    // computed
    let deviceRssi = props.deviceRssi;
    let signalStrengthColor = '';
    let signalStrength = 5;
    for(let [strength, rssi, color] of [
        [5, -60, '#1EA77B'],
        [4, -68, '#0092f5'],
        [3, -75, '#0092f5'],
        [2, -80, '#E70302'],
        [1, -90, '#E70302'],
    ]) {
        if (deviceRssi > (rssi as number)) {
            signalStrength = strength as any;
            signalStrengthColor = color as any;
            break;
        }
    }

    let blocks = [];
    for(let i=1; i<=5; i++) {
        blocks.push({
            margin: '0px 1px 0px 0px',
            width: '2px',
            height: (i / 5) * 100 + '%',
            backgroundColor: signalStrength < i ? 'rgba(255, 255, 255, 0.3)' : signalStrengthColor,
        });
    }

    // effect
    useEffect(()=> {
        setCountUpStart(countUpEnd);
        setCountUpEnd(deviceRssi);
    }, [deviceRssi]);

    // render
    return <DeviceRssiWrapper>
        <DeviceRssiText><CountUp start={countUpStart} end={countUpEnd} />dbm</DeviceRssiText>
        <DeviceRssiIcon>
            {
                blocks.map((e, index)=> {
                    return <div style={e} key={index}>
                    </div>
                })
            }
        </DeviceRssiIcon>
    </DeviceRssiWrapper>
}

export default DeviceRssi;
