
import styled from "styled-components";

export const DeviceAlarmsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 10px;
`;

export const AlarmsOverview = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const OverviewItemGroup = styled.div`
    width: calc(50% - 20px);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.1);
`;

export const OverviewItemSeparator = styled.div`
    width: 1px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.0) 10%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.0) 90%);
`;

export const OverviewItem = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0px 10px;
    font-style: italic;
`;

export const OverviewItemLabel = styled.div`
    padding: 3px 0px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
`;

export const OverviewItemValue = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 5px 0px;
`;

export const ProcessPercent = styled.div`
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    margin-left: 10px;
`;

export const AlarmEvents = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 20px;
`;


export const TableHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 5px;
`;

export const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const TableHeaderRow = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1.2fr 2fr 0.8fr;
    grid-gap: 5px;
    align-items: center;
    justify-items: stretch;
    background-color: rgba(255, 255, 255, 0.05);
    font-size: 12px;
    font-style: italic;
    
    & div {
        padding-top: 3px;
        padding-bottom: 3px;
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const TableRow = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1.2fr 2fr 0.8fr;
    grid-gap: 5px;
    align-items: center;
    justify-items: stretch;
    font-size: 12px;
    font-style: italic;
    padding: 4px 0px;
    cursor: pointer;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.025) 0%, rgba(255, 255, 255, 0.075) 50%, rgba(255, 255, 255, 0.025) 100%);
    }

    &:nth-child(even) {
        
    }
    
    &:hover {
        background: linear-gradient(0deg, rgba(55, 72, 108, 0.5) 0%, rgba(55, 72, 108, 0.0) 50%, rgba(55, 72, 108, 0.5) 100%);
    }

    &:hover:nth-child(even) {
        background: linear-gradient(0deg, rgba(55, 72, 108, 0.5) 0%, rgba(55, 72, 108, 0.0) 50%, rgba(55, 72, 108, 0.5) 100%);
    }

`;

export const DeviceName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 5px;
`;

export const EventTime = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    box-sizing: border-box;

    &.value {
        padding-top: 5px;
    }
`;

export const EventDetails = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;

export const EventStatus = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
`;


