class HtmlDomUtils {

    public static isDetached(element: HTMLElement): boolean {
        let temp=element;
        while(temp!=null && temp.parentElement!=temp) {
            if(temp.tagName.toLowerCase()=='body') {
                return false;
            }
            temp=temp.parentElement;
        }
        return true;
    }

    public static getClientOffsetToBody(element: HTMLElement): {offsetLeft: number, offsetTop: number} {
        let offset={offsetLeft:element.offsetLeft, offsetTop:element.offsetTop};
        let offsetParent=element.offsetParent;
        if(offsetParent!=null && !(offsetParent.localName=='body')) {
            let parentAny: any=offsetParent;
            let parentElement: HTMLElement=parentAny;
            let parentOffset=HtmlDomUtils.getClientOffsetToBody(parentElement);

            offset.offsetLeft+=parentOffset.offsetLeft;
            offset.offsetTop+=parentOffset.offsetTop;
        }

        let parentElement=element;
        while(true) {
            parentElement=parentElement.parentElement;
            if(parentElement==null) {
                break;
            }

            offset.offsetLeft-=parentElement.scrollLeft;
            offset.offsetTop-=parentElement.scrollTop;

            if(parentElement==offsetParent) {
                break;
            }
        }
        
        return offset;
    }

    public static removeChildren(element: Element): void {
        let children=element.children;
        let childrenArr: Element[] = [];
        
        for(let i=0;i<children.length;i++) {
            childrenArr.push(children.item(i));
        }

        for(let child of childrenArr) {
            child.remove();
        }
    }

    public static getPathToBody(element: Element): Element[] {
        let elements:Element[]=[];
        
        let tmp=element;
        while(true) {
            elements.push(tmp);
            if(tmp==null || tmp==document.body) {
                break;
            }

            tmp=tmp.parentElement;
        }

        return elements;
    }

    public static isElement(obj: any): boolean {
        if(obj==null) {
            return false;
        }

        if(obj.nodeType==1) {
            return true;
        }
        return false;
    }
}

export default HtmlDomUtils;