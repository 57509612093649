import { useEffect, useState } from "react";
import ProductApi from "../../../../../../api/ProductApi";
import { DeviceSettingsWrapper, GroupContent, GroupTitle, SettingDescription, SettingItem, SettingTitle, SettingValue, Settinglabel, SettingsGroup } from "./DeviceDefaultSettings.styled";
import StringUtils from "../../../../../../util/StringUtils";
import { Button, Input, InputNumber, Modal, Select, Space, Switch, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import DeviceApi from "../../../../../../api/DeviceApi";
import DeviceTemplateApi from "../../../../../../api/DeviceTemplateApi";


interface DeviceDefaultSettingsProps {
    deviceTemplateView: any
}

const DeviceDefaultSettings: React.FC<DeviceDefaultSettingsProps> = (props)=> {
    let { deviceTemplateView } = props;
    let deviceSettingsValue = deviceTemplateView.deviceTemplate.defaultDeviceSettings;

    // api
    let productApi = new ProductApi();
    let deviceTemplateApi = new DeviceTemplateApi();
    const [messageApi, contextHolder] = message.useMessage();

    // state
    let [deviceSettings, setDeviceSettings] = useState(StringUtils.isEmpty(deviceSettingsValue) ? {} as any: JSON.parse(deviceSettingsValue));
    let [product, setProduct] = useState(null);
    let [settingsGroups, setSettingsGroups] = useState([]);

    let [openSystemSettingsModal, setOpenSystemSettingsModal] = useState(false)
    let [editingSystemSettings, setEditingSystemSettings] = useState(null);

    let [openHeartBeatSettingsModal, setOpenHeartBeatSettingsModal] = useState(false);
    let [editingHeartBeatSetttings, setEditingHeartBeatSettings] = useState(null);

    let [openAdcSettingsModal, setOpenAdcSettingsModal] = useState(false);
    let [editingAdcSettings, setEditingAdcSettings] = useState(null);

    let [openCustomizedSettingsModal, setOpenCustomizedSettingsModal] = useState(false);
    let [editingCustomizedSettings, setEditingCustomizedSettings] = useState(null);
    
    // load product specifications
    let loadProductModel = async ()=> {
        // get product
        let product = (await productApi.getProductByModel(deviceTemplateView.deviceTemplate.productModel)).data.data;
        setProduct(product);

        // parse settings
        let settings = JSON.parse(product.settings);
        let settingsGroups = settings.settingsGroups;
        setSettingsGroups(settingsGroups);
    };

    // effect
    useEffect(()=> {
        loadProductModel();
    }, []);

    // render
    let getIntervalUnitText = (unit: string)=> {
        if (unit == null) {
            unit = '';
        }
        unit = unit.toLowerCase();
        switch(unit) {
            case 'ms': 
                return 'ms';
            case 's':
                return '秒';
            case 'min':
                return '分';
            case 'hour':
                return '小时';
            case 'day':
                return '天';
        }
        return '';
    };

    let getSettingsGroupContent = (settingsGroup: any)=> {
        switch(settingsGroup.type.toLowerCase()) {
            case 'standard_system':
                return getSettingsGroupContentSystem(settingsGroup);
            case 'standard_heart_beat':
                return getSettingsGroupContentHeartBeat(settingsGroup);
            case 'standard_adc':
                return getSettingsGroupContentAdc(settingsGroup);
            case 'customized': 
                return getSettingsGroupContentCustomized(settingsGroup);
        }
        return <div></div>;
    };

    let getSettingsGroupContentSystem = (settingsGroup)=> {
        let saveSystemSettings = async ()=> {
            deviceSettings.system = editingSystemSettings;
            await deviceTemplateApi.saveSettings(deviceTemplateView.deviceTemplate.id, settingsGroup.type, deviceSettings.system);
            messageApi.open({
                type: 'success',
                content: '保存系统配置成功',
            });
            setOpenSystemSettingsModal(false);
        };

        let onOpenSystemSettingsModal = ()=> {
            let systemSettings = deviceSettings.system;
            if (systemSettings == null) {
                systemSettings = {};
            }
            setEditingSystemSettings({...systemSettings});
            setOpenSystemSettingsModal(true);
        };

        let onHttpServerChange = (e)=> {
            editingSystemSettings.httpServer = e.target.value;
        };

        let onMqttServerChange = (e)=> {
            editingSystemSettings.mqttServer = e.target.value;
        };

        return (
            <SettingsGroup key={settingsGroup.key}>
                <GroupTitle>
                    {settingsGroup.name} 
                    <Button type='link' onClick={onOpenSystemSettingsModal}><EditOutlined />编辑</Button></GroupTitle>
                <GroupContent>
                    <SettingItem>
                        <Settinglabel>
                            <SettingTitle>HTTP服务器地址</SettingTitle>
                            <SettingDescription>用于设备注册，固件下载等操作，修改重启生生效</SettingDescription>
                        </Settinglabel>
                        <SettingValue>{deviceSettings.system?.httpServer}</SettingValue>
                    </SettingItem>

                    <SettingItem>
                        <Settinglabel>
                            <SettingTitle>MQTT服务器地址</SettingTitle>
                            <SettingDescription>用于采集数据传输，参数下载等操作，修改重启后生效</SettingDescription>
                        </Settinglabel>
                        <SettingValue>{deviceSettings.system?.mqttServer}</SettingValue>
                    </SettingItem>
                </GroupContent>
                <Modal open={openSystemSettingsModal} 
                    onClose={()=> setOpenSystemSettingsModal(false)} 
                    closable={false}
                    width={800}
                    height={600}
                    title="系统设置"
                    footer={
                        <Space size="middle">
                            <Button type="primary" onClick={()=> saveSystemSettings()}>保存</Button>
                            <Button type="primary" danger onClick={()=> setOpenSystemSettingsModal(false)}>取消</Button>
                        </Space>
                    }
                >
                    <GroupContent>
                        <SettingItem>
                            <Settinglabel>
                                <SettingTitle>HTTP服务器地址</SettingTitle>
                                <SettingDescription>用于设备注册，固件下载等操作，修改重启生生效</SettingDescription>
                            </Settinglabel>
                            <Input defaultValue={editingSystemSettings?.system?.httpServer} 
                                onChange={onHttpServerChange} 
                                style={{
                                    flex: '1 1 auto',
                                    margin: '0px 10px'
                                }}
                            />
                        </SettingItem>

                        <SettingItem>
                            <Settinglabel>
                                <SettingTitle>MQTT服务器地址</SettingTitle>
                                <SettingDescription>用于采集数据传输，参数下发等操作，修改重启后生效</SettingDescription>
                            </Settinglabel>
                            <Input defaultValue={editingSystemSettings?.system?.mqttServer} 
                                onChange={onMqttServerChange} 
                                style={{
                                    flex: '1 1 auto',
                                    margin: '0px 10px'
                                }}
                            />
                        </SettingItem>
                    </GroupContent>                    
                </Modal>
            </SettingsGroup>
        )
    };

    let getSettingsGroupContentHeartBeat = (settingsGroup)=> {
        let saveHeartBeatSettings = async ()=> {
            deviceSettings.heartBeat = editingHeartBeatSetttings;
            await deviceTemplateApi.saveSettings(deviceTemplateView.deviceTemplate.id, settingsGroup.type, deviceSettings.heartBeat);
            messageApi.open({
                type: 'success',
                content: '保存GNSS/心跳配置成功',
            });
            setOpenHeartBeatSettingsModal(false);
        };

        let updateHeartBeatIntervalMs = ()=> {
            let interval = editingHeartBeatSetttings.interval;
            let intervalUnit = editingHeartBeatSetttings.intervalUnit;
            if (intervalUnit == null) {
                intervalUnit = 'ms';
            }
            let intervalMs = interval;
            switch(intervalUnit.toLowerCase()) {
                case 'ms':
                    intervalMs = interval;
                    break;
                case 's':
                    intervalMs = interval * 1000;
                    break;
                case 'min':
                    intervalMs = interval * 1000 * 60;
                    break;
                case 'hour':
                    intervalMs = interval * 1000 * 60 * 60;
                    break;
            }
            editingHeartBeatSetttings.intervalMs = intervalMs;
            setEditingHeartBeatSettings({...editingHeartBeatSetttings});
        };

        let onOpenHeartBeatSettingsModal = ()=> {
            let heartBeatSettings = deviceSettings.heartBeat;
            if (heartBeatSettings == null) {
                heartBeatSettings = {};
            }
            setEditingHeartBeatSettings({...heartBeatSettings});
            setOpenHeartBeatSettingsModal(true);
        };

        let onHeartBeatIntervalChange = (value)=> {
            editingHeartBeatSetttings.interval = parseInt(value);
            updateHeartBeatIntervalMs();
        };

        let onHeartBeatIntervalUnitChange = (value)=> {
            editingHeartBeatSetttings.intervalUnit = value;
            updateHeartBeatIntervalMs();
        };

        let saveEnabled = editingHeartBeatSetttings?.interval != null && !StringUtils.isEmpty(editingHeartBeatSetttings?.intervalUnit);

        return (
            <SettingsGroup key={settingsGroup.key}>
                <GroupTitle>
                    {settingsGroup.name} 
                    <Button type='link' onClick={onOpenHeartBeatSettingsModal}><EditOutlined />编辑</Button>
                </GroupTitle>
                <GroupContent>
                    <SettingItem>
                        <Settinglabel>
                            <SettingTitle>GNSS/心跳上报时间间隔</SettingTitle>
                            <SettingDescription>设置GNSS 心跳数据包上报时间间隔，如需录制轨迹，请设置短一些的间隔以获取准确轨迹</SettingDescription>
                        </Settinglabel>
                        <SettingValue>{deviceSettings.heartBeat?.interval} {getIntervalUnitText(deviceSettings.heartBeat?.intervalUnit)}</SettingValue>
                    </SettingItem>
                </GroupContent>

                <Modal open={openHeartBeatSettingsModal} 
                    onClose={()=> setOpenHeartBeatSettingsModal(false)} 
                    closable={false}
                    width={800}
                    height={600}
                    title="GNSS/心跳设置"
                    footer={
                        <Space size="middle">
                            <Button type="primary" disabled={!saveEnabled} onClick={()=> saveHeartBeatSettings()}>保存</Button>
                            <Button type="primary" danger onClick={()=> setOpenHeartBeatSettingsModal(false)}>取消</Button>
                        </Space>
                    }
                >
                    <GroupContent>
                        <SettingItem>
                            <Settinglabel>
                                <SettingTitle>GNSS/心跳上报时间间隔</SettingTitle>
                                <SettingDescription>设置GNSS 心跳数据包上报时间间隔，如需录制轨迹，请设置短一些的间隔以获取准确轨迹</SettingDescription>
                            </Settinglabel>
                            <InputNumber 
                                min={0}
                                max={999999}
                                defaultValue={editingHeartBeatSetttings?.interval}
                                onChange={onHeartBeatIntervalChange}
                                addonAfter={
                                    <Select 
                                        style={{width: '70px'}}
                                        defaultValue={editingHeartBeatSetttings?.intervalUnit?.toLowerCase()} 
                                        onChange={onHeartBeatIntervalUnitChange}>
                                        <Select.Option value="ms">毫秒</Select.Option>
                                        <Select.Option value="s">秒</Select.Option>
                                        <Select.Option value="min">分</Select.Option>
                                        <Select.Option value="hour">小时</Select.Option>
                                    </Select>
                                }
                                style={{
                                    flex: '1 1 auto',
                                    margin: '0px 10px'
                                }}
                            />
                        </SettingItem>
                    </GroupContent>
                </Modal>
            </SettingsGroup>
        )
    };

    let getSettingsGroupContentAdc = (settingsGroup)=> {
        let saveAdcSettings = async ()=> {
            deviceSettings.adc = editingAdcSettings;
            await deviceTemplateApi.saveSettings(deviceTemplateView.deviceTemplate.id, settingsGroup.type, deviceSettings.adc);
            messageApi.open({
                type: 'success',
                content: '保存ADC参数配置成功',
            });
            setOpenAdcSettingsModal(false);
        };

        let getChannelSettings = (channel: number, editing=false)=> {
            let adcSettings = editing ? editingAdcSettings : deviceSettings.adc;
            if (adcSettings==null) {
                return null;
            }
            let channelSettings = adcSettings[`ch${channel}`];
            if (channelSettings==null) {
                return null;
            }
            return channelSettings;
        }

        let getChannelEnabled = (channel: number, editing = false)=> {
            let channelSettings = getChannelSettings(channel, editing);
            if (channelSettings ==  null) {
                return true; // default to true
            }
            return channelSettings.enabled;
        };

        let getChannelInterval = (channel: number, editing = false)=> {
            let channelSettings = getChannelSettings(channel, editing);
            if (channelSettings ==  null) {
                return '';
            }
            return channelSettings.publishInterval;
        }

        let getChannelIntervalUnit = (channel: number, editing = false)=> {
            let channelSettings = getChannelSettings(channel, editing);
            if (channelSettings ==  null) {
                return '';
            }
            return channelSettings.publishIntervalUnit?.toLowerCase();
        }

        let getChannelPublishIntervalText = (channel: number)=> {
            let adcSettings = deviceSettings.adc;
            if (adcSettings==null) {
                return '/';
            }
            let channelSettings = adcSettings[`ch${channel}`];
            if (channelSettings==null) {
                return '/';
            }
            return `${StringUtils.nonull(channelSettings.publishInterval)} ${getIntervalUnitText(channelSettings.publishIntervalUnit)}`;
        };

        let getChannelSettingsCreate = (channel: number)=> {
            let adcSettings = editingAdcSettings;
            let channelSettings = adcSettings[`ch${channel}`];
            if (channelSettings==null) {
                channelSettings = {};
                adcSettings[`ch${channel}`] = channelSettings;
            }
            return channelSettings;
        };

        let onOpenAdcSettingsModal = ()=> {
            let editingAdcSettings = deviceSettings.adc;
            if (editingAdcSettings == null) {
                editingAdcSettings = {};
            }
            setEditingAdcSettings({...editingAdcSettings});
            setOpenAdcSettingsModal(true)
        };

        let onChannelEnableChange = (checked, channel)=> {
            let channelSettings = getChannelSettingsCreate(channel);
            channelSettings.enabled = checked;
            setEditingAdcSettings({...editingAdcSettings});
        };

        let updateChannelIntervalMs = (channel)=> {
            let channelSettings = getChannelSettingsCreate(channel)
            let interval = channelSettings.publishInterval;
            if (interval == null) {
                interval = 0;
            }

            let intervalUnit = channelSettings.publishIntervalUnit;
            if (intervalUnit == null) {
                intervalUnit = 'ms';
            }
            let intervalMs = interval;
            switch(intervalUnit.toLowerCase()) {
                case 'ms':
                    intervalMs = interval;
                    break;
                case 's':
                    intervalMs = interval * 1000;
                    break;
                case 'min':
                    intervalMs = interval * 1000 * 60;
                    break;
                case 'hour':
                    intervalMs = interval * 1000 * 60 * 60;
                    break;
            }
            channelSettings.publishIntervalMs = intervalMs;
            setEditingAdcSettings({...editingAdcSettings});
        };

        let onChannelIntervalChange = (value, channel)=> {
            let channelSettings = getChannelSettingsCreate(channel);
            channelSettings.publishInterval = parseInt(value);
            updateChannelIntervalMs(channel);
        };

        let onChannelIntervalUnitChange = (value, channel)=> {
            let channelSettings = getChannelSettingsCreate(channel);
            channelSettings.publishIntervalUnit = value;
            updateChannelIntervalMs(channel);
        };

        let channels: number[] = [];
        for(let i=1; i<=settingsGroup.channels; i++) {
            channels.push(i);
        }

        let saveEnabled = true;
        if (editingAdcSettings) {
            for(let i=1; i<=settingsGroup.channels; i++) {
                let channelSettings = getChannelSettings(i, true);
                if (channelSettings == null) {
                    saveEnabled = false;
                    break;
                }
                if (channelSettings.enabled == false) {
                    // nop
                } else {
                    if (channelSettings.publishInterval == null || isNaN(channelSettings.publishInterval) || StringUtils.isEmpty(channelSettings.publishIntervalUnit)) {
                        saveEnabled = false;
                        break;
                    }
                }
            }
        }

        return <SettingsGroup key={settingsGroup.key}>
            <GroupTitle>
                {settingsGroup.name} 
                <Button type='link' onClick={onOpenAdcSettingsModal}><EditOutlined />编辑</Button>
            </GroupTitle>
            <GroupContent>
                {
                    channels.map((channel)=> {
                        return <SettingItem key={channel}>
                            <Settinglabel>
                                <SettingTitle>通道{channel}参数</SettingTitle>
                                <SettingDescription>设置启用/禁用，上报时间间隔</SettingDescription>
                            </Settinglabel>
                            <SettingValue>
                                <Switch disabled={true} checked={getChannelEnabled(channel)} style={{marginRight: '20px'}} /> 
                                上报时间间隔：{getChannelPublishIntervalText(channel)}
                            </SettingValue>
                        </SettingItem>
                    })
                }
            </GroupContent>

            
            <Modal open={openAdcSettingsModal} 
                onClose={()=> setOpenAdcSettingsModal(false)} 
                closable={false}
                width={1000}
                height={600}
                title="ADC参数设置"
                footer={
                    <Space size="middle">
                        <Button type="primary" disabled={ !saveEnabled } onClick={()=> saveAdcSettings()}>保存</Button>
                        <Button type="primary" danger onClick={()=> setOpenAdcSettingsModal(false)}>取消</Button>
                    </Space>
                }
            >
                <GroupContent>
                    {
                        channels.map((channel)=> {
                            return <SettingItem key={channel}>
                                <Settinglabel>
                                    <SettingTitle>通道{channel}参数</SettingTitle>
                                    <SettingDescription>设置启用/禁用，上报时间间隔</SettingDescription>
                                </Settinglabel>
                                <SettingValue>
                                    <Switch checked={getChannelEnabled(channel, true)} 
                                    style={{marginRight: '20px'}}
                                    onChange={(checked)=> onChannelEnableChange(checked, channel)}
                                /> 
                                    <div style={{flex: '0 0 auto'}}>上报时间间隔：</div>
                                    <InputNumber 
                                        min={0}
                                        max={999999}
                                        onChange={(e)=> onChannelIntervalChange(e, channel)}
                                        defaultValue={getChannelInterval(channel, true)}
                                        value={getChannelInterval(channel, true)}
                                        
                                        addonAfter={
                                            <Select defaultValue={getChannelIntervalUnit(channel, true)} 
                                                value={getChannelIntervalUnit(channel, true)}
                                                style={{width: '70px'}}
                                                onChange={(e)=> {
                                                    onChannelIntervalUnitChange(e, channel);
                                                }}
                                            >
                                                <Select.Option value="ms">毫秒</Select.Option>
                                                <Select.Option value="s">秒</Select.Option>
                                                <Select.Option value="min">分</Select.Option>
                                                <Select.Option value="hour">小时</Select.Option>
                                            </Select>
                                        }
                                        style={{
                                            flex: '1 1 auto',
                                            margin: '0px 10px'
                                        }}
                                    />
                                </SettingValue>
                            </SettingItem>
                        })
                    }
                </GroupContent>
            </Modal>
        </SettingsGroup>
    };

    let getSettingsGroupContentCustomized = (settingsGroup)=> {
        let saveCustomizedSettings = async ()=> {
            deviceSettings.customized = editingCustomizedSettings;
            await deviceTemplateApi.saveSettings(deviceTemplateView.deviceTemplate.id, settingsGroup.type, deviceSettings.customized);
            messageApi.open({
                type: 'success',
                content: `保存${settingsGroup.name}配置成功`,
            });
            setOpenCustomizedSettingsModal(false);
        };

        let onOpenCustomizedSettingsModal = ()=> {
            let customizedSettings = deviceSettings.customized;
            if (customizedSettings == null) {
                customizedSettings = {};
            }
            setEditingCustomizedSettings({...customizedSettings});
            setOpenCustomizedSettingsModal(true);
        };

        let onCustomizedSettingItemValueChange = (settingItem, value)=> {
            editingCustomizedSettings[settingItem.key] = value;
            setEditingCustomizedSettings({...editingCustomizedSettings});
        };

        let getCustomizedSettingItemRenderValue = (customizedSettings: any, settingItem: any)=> {
            if (customizedSettings==null) {
                return '';
            }
            if (settingItem.type=='BOOL') {
                return <Switch checked={getCustomizedSettingItemValue(editingCustomizedSettings, settingItem.key)} 
                    style={{marginRight: '20px'}}
                    disabled={true}
                />
            }
            return customizedSettings[settingItem.key];
        };

        let getCustomizedSettingItemValue = (customizedSettings: any, settingItem: any)=> {
            if (customizedSettings==null) {
                return '';
            }
            return customizedSettings[settingItem.key];
        };

        
        let saveEnabled = true;
        if (editingCustomizedSettings) {
            for(let i=0; i<settingsGroup.settingItems.length; i++) {
                let settingItem = settingsGroup.settingItems[i];
                let settingItemValue = getCustomizedSettingItemValue(editingCustomizedSettings, settingItem);
                if (StringUtils.isEmpty(settingItemValue)) {
                    if (settingItem.nullable !=null && settingItem.nullable) {
                        // nop
                    } else {
                        saveEnabled = false;
                        break;
                    }
                }
            }
        }

    return (
            <SettingsGroup key={settingsGroup.key}>
                <GroupTitle>
                    {settingsGroup.name} 
                    <Button type='link' onClick={onOpenCustomizedSettingsModal}><EditOutlined />编辑</Button>
                </GroupTitle>
                <GroupContent>
                    {
                        settingsGroup.settingItems.map((settingItem)=> {
                            return <SettingItem key={settingItem.key}>
                                <Settinglabel>
                                    <SettingTitle>{settingItem.name}</SettingTitle>
                                    <SettingDescription>{settingItem.description}</SettingDescription>
                                </Settinglabel>
                                <SettingValue>{getCustomizedSettingItemRenderValue(deviceSettings.customized, settingItem)}</SettingValue>
                            </SettingItem>
                        })
                    }
                </GroupContent>

                <Modal open={openCustomizedSettingsModal} 
                    onClose={()=> setOpenCustomizedSettingsModal(false)} 
                    closable={false}
                    width={800}
                    height={600}
                    title={settingsGroup.name}
                    footer={
                        <Space size="middle">
                            <Button type="primary" disabled={!saveEnabled} onClick={()=> saveCustomizedSettings()}>保存</Button>
                            <Button type="primary" danger onClick={()=> setOpenCustomizedSettingsModal(false)}>取消</Button>
                        </Space>
                    }
                >
                    <GroupContent>
                        { settingsGroup.settingItems.map((settingItem)=> {
                            return <SettingItem>
                                <Settinglabel>
                                    <SettingTitle>{settingItem.name}</SettingTitle>
                                    <SettingDescription>{settingItem.description}</SettingDescription>
                                </Settinglabel>
                                {
                                    (()=> {
                                        if (settingItem.type=='INT') {
                                            return <InputNumber 
                                                min={settingItem.min}
                                                max={settingItem.max}
                                                precision={0}
                                                stringMode={true}
                                                step={1}
                                                defaultValue={getCustomizedSettingItemValue(editingCustomizedSettings, settingItem)}
                                                onChange={(value)=> onCustomizedSettingItemValueChange(settingItem, value)}
                                                style={{
                                                    flex: '1 1 auto',
                                                    margin: '0px 10px'
                                                }}
                                            />
                                        } else if (settingItem.type=='FLOAT') {
                                            return <InputNumber 
                                                min={settingItem.min}
                                                max={settingItem.max}
                                                stringMode={true}
                                                defaultValue={getCustomizedSettingItemValue(editingCustomizedSettings, settingItem)}
                                                onChange={(value)=> onCustomizedSettingItemValueChange(settingItem, value)}
                                                style={{
                                                    flex: '1 1 auto',
                                                    margin: '0px 10px'
                                                }}
                                            />
                                        } else if (settingItem.type=='STRING') {
                                            return <Input 
                                                defaultValue={getCustomizedSettingItemValue(editingCustomizedSettings, settingItem)}
                                                onChange={(e)=> onCustomizedSettingItemValueChange(settingItem, e.target.value)}
                                                style={{
                                                    flex: '1 1 auto',
                                                    margin: '0px 10px'
                                                }}
                                            />
                                        } else if (settingItem.type=='BOOL') {
                                            return <Switch checked={getCustomizedSettingItemValue(editingCustomizedSettings, settingItem.key)} 
                                                style={{marginRight: '20px'}}
                                                onChange={(checked)=> onCustomizedSettingItemValueChange(settingItem, checked)}
                                            />
                                        } else {
                                            return <Input 
                                                defaultValue={getCustomizedSettingItemValue(editingCustomizedSettings, settingItem)}
                                                onChange={(e)=> onCustomizedSettingItemValueChange(settingItem, e.target.value)}
                                                style={{
                                                    flex: '1 1 auto',
                                                    margin: '0px 10px'
                                                }}
                                            />
                                        }
                                    })()
                                }
                            </SettingItem>
                        }) }
                    </GroupContent>
                </Modal>
            </SettingsGroup>
        )
    };

    return (
        <DeviceSettingsWrapper>
            {
                settingsGroups.map((settingsGroup)=> {
                    return <div key={settingsGroup.key}>
                        { getSettingsGroupContent(settingsGroup) }
                    </div>
                })
            }
            {contextHolder}
        </DeviceSettingsWrapper>
    )
}

export default DeviceDefaultSettings;
