import { ECharts } from "echarts";
import * as echarts from "echarts";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ChartHeader, ChartUnit, ChartName, ChartWrapper, DeviceMetricsWrapper, EChartsWrapper, ChartCurrentValue, ChartLatestMetricTime, ChartInfo, ChartHeaderColorBlock } from "./RealtimeDeviceMetrics.styled";
import DeviceMetricApi from "../../../../../../api/DeviceMetricApi";
import StringUtils from "../../../../../../util/StringUtils";
import SeriesColorUtils from "../../../../../../util/SeriesColorUtils";

interface DeviceMetricProps {
    deviceView: any
}

const RealtimeDeviceMetrics: React.FC<DeviceMetricProps> = (props)=> {
    // params
    let {deviceView} = props;

    // api
    let deviceMetricApi = new DeviceMetricApi();

    // ref
    let echartsDomsRef = useRef<HTMLElement[]>([]);
    let echartsRef =useRef<ECharts[]>([]);
    let deviceMetricsSeriesListRef = useRef([]);

    // state
    let [deviceMetricProperties, setDeviceMetricProperties] = useState([]);
    let [repaint, setRepaint] = useState(null);

    // functions
    let fetchRealtimeMetricsSeries = async ()=> {
        // fetch data
        let deviceMetricsSeriesList = (await deviceMetricApi.getRealtimeDeviceMetricsSeries(deviceView.device.id)).data.data;
        return deviceMetricsSeriesList;
    }

    let updateSeriesData = async (deviceMetricsSeriesList: any[])=> {
        // process
        for(let i=0; i<deviceMetricsSeriesList.length; i++) {
            let deviceMetricsSeries = deviceMetricsSeriesList[i];
            
            // series data
            let seriesData = [];
            let metrics = deviceMetricsSeries.metrics;
            let lastMetricTimestamp: number = null;
            let paddingGapMillis = 5 * 10000;
            for (let i = 0; i < metrics.length; i++) {
                let metric = metrics[i];
                
                // pad data point
                if (lastMetricTimestamp !=null && Math.abs(metric.timestamp - lastMetricTimestamp) > paddingGapMillis) {
                    // down data point
                    let downDataPoint = {name: '' + (Number(lastMetricTimestamp)+1), value: [(Number(lastMetricTimestamp)+1), 0]};
                    seriesData.push(downDataPoint);

                    // up data point
                    let upDataPoint: any = {name: '' + (Number(metric.timestamp)-1), value: [(Number(metric.timestamp)-1), 0]};
                    seriesData.push(upDataPoint);
                }
                lastMetricTimestamp = metric.timestamp;

                // add to series data
                seriesData.push({
                    name: metric.timestamp + '',
                    value: [Number(metric.timestamp), Number(metric.value)],
                });
            }

            // echarts options
            if (echartsRef.current[i] != null) {
                echartsRef.current[i].setOption({
                    series: [{
                        data: seriesData
                    }]
                });
            }
        }
    };

    let formatTimestampAxis = (value)=> {
        let text = moment(value).format('YYYY/MM/DD HH:mm:ss');
        text=text.replace(' ', '\n');
        return text;
    }
    
    // init ECharts
    let initECharts = (dom: HTMLElement, index: number)=> {
        if (dom == null) {
            // element unmount event
            // let echarts = echartsRef.current[index];
            // echarts.dispose();
            return;
        }
        if (echarts.getInstanceByDom(dom)) {
            return;
        }

        // save to reference
        echartsDomsRef.current.push(dom);
        let seriesIndex = echartsDomsRef.current.indexOf(dom);

        // init echarts
        let chart = echarts.init(dom);
        echartsRef.current.push(chart);

        // series
        let property = deviceMetricProperties[seriesIndex];

        // init options
        let option = {
            backgroundColor: 'transparent',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    animation: false
                },
                backgroundColor: "rgba(0,0,0,0.7)",
                confine: true,
                formatter: function(params) {
                    if (params == null) {
                        return '';
                    }

                    let seriesContent = '';
                    for(let param of params) {
                        if (param.value==null) {
                            continue;
                        }
                        
                        seriesContent += `
                            <div style="color: #EEEEEE;">
                                <div style="margin: 0px 0px 5px 0px;">
                                    <div style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; margin-right: 5px; background-color: ${param.color}"></div>
                                    <div style="display: inline-block">${param.seriesName}</div>
                                </div>
                                <div style="padding: 5px 0px;">
                                    <span style="display: inline-block; width: 50px;">测量值:</span> 
                                    <span style="display: inline-block">${param.value[1]} ${property.unit}</span>
                                </div>
                                <div>
                                    <span style="display: inline-block; width: 50px;">时间:</span> 
                                    <span style="display: inline-block">${moment(param.value[0]).format('YYYY-MM-DD HH:mm:ss')}</span>
                                </div>
                            </div>
                        `;
                        break;
                    }
                    let content=`
                        <div style="width: 220px;">
                            ${seriesContent}
                        </div>
                    `;
                    return content;
                },
            },
            grid: {
                x: 50,
                y: 20,
                x2: 20,
                y2: 40,
            },
            xAxis: {
                type: 'time',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#CCCCCC',
                        width: 1,
                        type: 'dashed',
                    },
                },
                splitNumber: 2,
                minorTick: {
                    show: false,
                },
                axisLabel: {
                    formatter: function (value, index) {
                        return formatTimestampAxis(value);
                    }
                },
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, '100%'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#CCCCCC',
                        width: 1,
                        type: 'dashed',
                    }
                },
                nameTextStyle: {
                    align: 'left',
                },
            },
            dataZoom: [
                {
                  type: 'inside',
                  start: 90,
                  end: 100
                }
            ],
            series: [
                {
                    name: property.name,
                    type: 'line',
                    color: SeriesColorUtils.getSeriesColor(seriesIndex),
                    smooth: false,
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.2,
                    },
                    data: [],
                    shadowBlur: 20,
                }
            ],
        };

        chart.setOption(option);
        chart.group='DeviceRealtimeMetrics';
        echarts.connect('DeviceRealtimeMetrics');

        if (deviceMetricProperties.length == seriesIndex + 1) {
            // fetch data
            setTimeout(()=> {
                updateSeriesData(deviceMetricsSeriesListRef.current);
            });
        }
    }

    // effect
    useEffect(()=> {
        let refreshTaskId: any = null;
        let disposed = false;

        let init = async ()=> {
            // fetch data
            let deviceMetricsSeriesList = await fetchRealtimeMetricsSeries();
            deviceMetricsSeriesListRef.current = deviceMetricsSeriesList;

            // device template properties
            if (deviceMetricProperties.length <=0 ) {
                deviceMetricProperties = [];
                for(let deviceMetricsSeries of deviceMetricsSeriesList) {
                    deviceMetricProperties.push(deviceMetricsSeries.property);
                }
                setDeviceMetricProperties(deviceMetricProperties);    
            } else {
                updateSeriesData(deviceMetricsSeriesList);
            }

            // refresh task
            refreshTaskId = window.setInterval(async ()=> {
                deviceMetricsSeriesList = await fetchRealtimeMetricsSeries();
                deviceMetricsSeriesListRef.current = deviceMetricsSeriesList;
                updateSeriesData(deviceMetricsSeriesList);
                setDeviceMetricProperties([].concat(deviceMetricProperties))

                if (disposed) {
                    window.clearInterval(refreshTaskId);
                }
            }, 7500);
        };

        init();

        
        // resize charts on window resize
        let resizeListener = ()=> {
            for(let chart of echartsRef.current) {
                chart.resize();
            }
        };
        window.addEventListener('resize', resizeListener);

        // unload
        return ()=> {
            disposed = true;
            for(let chart of echartsRef.current) {
                chart.dispose();
            }

            // clear interval
            if (refreshTaskId!=null) {
                window.clearInterval(refreshTaskId);
            }

            // clear resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, []);

    // render
    let getLatestMetricTime = (index: number)=> {
        let deviceMetricsSeries = deviceMetricsSeriesListRef.current[index];
        if (deviceMetricsSeries != 0) {
            let metrics = deviceMetricsSeries.metrics;
            if (metrics.length > 0) {
                let latestMetrics = metrics[metrics.length-1];
                let time = moment(Number(latestMetrics.timestamp)).format('YYYY/MM/DD HH:mm:ss');
                return time;
            }
        }
        return '';
    };

    let getLatestMetricValue = (index: number)=> {
        let deviceMetricsSeries = deviceMetricsSeriesListRef.current[index];
        if (deviceMetricsSeries != 0) {
            let metrics = deviceMetricsSeries.metrics;
            if (metrics.length > 0) {
                let latestMetrics = metrics[metrics.length-1];
                let text = latestMetrics.value;
                return text;
            }
        }
        return '';
    };

    let paddingChartWrapper = [];
    for(let i=deviceMetricProperties.length; i<4; i++) {
        paddingChartWrapper.push(i);
    }

    return (
        <DeviceMetricsWrapper>
            {
                deviceMetricProperties.map((e, index)=> {
                    return (
                        <ChartWrapper key={e.name}>
                            <ChartHeader>
                                <ChartName>{e.name}</ChartName>
                                <ChartHeaderColorBlock style={{backgroundColor: SeriesColorUtils.getSeriesColor(index)}} />
                                <ChartCurrentValue>{getLatestMetricValue(index)}</ChartCurrentValue>
                                <ChartInfo>
                                    <ChartLatestMetricTime>最后更新时间：{getLatestMetricTime(index)}</ChartLatestMetricTime>
                                    <ChartUnit>单位：{StringUtils.emptyToSlash(e.unit)}</ChartUnit>
                                </ChartInfo>
                            </ChartHeader>
                            <EChartsWrapper ref={(dom)=> initECharts(dom, index)}>
                            </EChartsWrapper>
                        </ChartWrapper>
                    )
                })
            }
            {
                paddingChartWrapper.map((e, index)=> {
                    return (
                        <ChartWrapper key={e}></ChartWrapper>
                    )
                })
            }
        </DeviceMetricsWrapper>
    )
}

export default RealtimeDeviceMetrics;
