import { useLocation, useNavigate } from "react-router-dom";
import Menu, { MenuItem } from "../../menu/Menu";
import { LogoDescription, LogoText, LogoTextEm, LogoWrapper, MainSideBarWrapper, MenuGroup, MenuGroupContent, MenuGroupTitle, MenuItemText, MenuItemWrapper, MenuWrapper } from "./MainSideBar.styled";
import ViewStateService from "../../../../services/view-state/ViewStateService";

interface MainSideBarProps {

}

const MainSideBar: React.FC<MainSideBarProps> = (props)=> {
    let menu = Menu.getMainMenu();
    let location = useLocation();
    let navigate = useNavigate();

    let onMenuItemClick = (menuItem: MenuItem)=> {
        navigate(menuItem.path);
        ViewStateService.getInstance().setStatusBarTitle(menuItem.name);
    }

    let isSelected = (menuItem: MenuItem)=> {
        let pathname = location.pathname;
        if (pathname.startsWith(menuItem.path)) {
            ViewStateService.getInstance().setStatusBarTitle(menuItem.name);
            return true;
        }
        return false;
    }

    return (
        <MainSideBarWrapper>
            <LogoWrapper>
                <LogoText><LogoTextEm>W</LogoTextEm>inch <LogoTextEm>L</LogoTextEm>ink <LogoTextEm>S</LogoTextEm>ystem</LogoText>
                <LogoDescription>智能卷扬机互联系统</LogoDescription>
            </LogoWrapper>

            <MenuWrapper>
                {
                    menu.groups.map((menuGroup)=> {
                        return (
                            <MenuGroup key={menuGroup.name}>
                                <MenuGroupTitle>{menuGroup.name}</MenuGroupTitle>
                                <MenuGroupContent>
                                    {
                                        menuGroup.items.map((menuItem)=> {
                                            return (
                                                <MenuItemWrapper key={menuItem.path} className={isSelected(menuItem) ? "active": ""} onClick={()=> onMenuItemClick(menuItem)}>
                                                    <MenuItemText>{menuItem.name}</MenuItemText>
                                                </MenuItemWrapper>
                                            )
                                        })
                                    }
                                </MenuGroupContent>
                            </MenuGroup>
                        )
                    })
                }
            </MenuWrapper>

        </MainSideBarWrapper>
    )
}

export default MainSideBar;

