import styled from "styled-components";

export const MainSideBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    user-select: none;
`

export const LogoWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px 20px 0px;
`;

export const LogoText = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #4F4F4F;
    
`;

export const LogoTextEm = styled.span`
    font-size: 24px;
    font-weight: bold;
    color: #BD3124;
    line-height: 150%;
`;

export const LogoDescription = styled.div`
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 4px; 
    color: #6C6C6C;
    line-height: 200%;
    margin-left: -15px;
`;

export const MenuWrapper = styled.div`
    flex: 1 1 auto;
    padding: 5px 25px 20px 15px;
    overflow-y: auto;
`;

export const MenuGroup = styled.div`
    border-bottom: 1px solid #EEEEEE;
    letter-spacing: 2px;
    padding: 10px 0px;

    &:last-child {
        border-bottom: none;
    }
`;

export const MenuGroupTitle = styled.div`
    font-size: 12px;
    letter-spacing: 2px;
    color: #9A9A9A;
    padding: 5px 10px;
`;

export const MenuGroupContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const MenuItemWrapper = styled.div`
    border-radius: 5px;
    padding: 5px 10px;
    
    &:hover {
        background-color: #EFEFEF;
    }

    &.active {
        background-color: #CCCCCC;
        font-weight: bold;
    }
`;

export const MenuItemText = styled.div`
    font-size: 14px;
    letter-spacing: 2px;
    color: #000000;
    line-height: 150%;
`;

