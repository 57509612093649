import { useEffect, useRef, useState } from "react";
import DeviceApi from "../../../../../api/DeviceApi";
import { Content, DeviceIndex, DeviceName, DeviceNameAndDuration, DeviceOnlineDurationItem, DeviceOnlineDurationWrapper, DurationText } from "./DeviceOnlineDuration.styled";
import TimeUtils from "../../../../../util/TimeUtils";
import AutoScrollUtils from "../../../../../util/AutoScrollUtils";
import { Progress, ProgressProps} from "antd";
import EventManager from "../../common/event-manager/EventManager";

interface DeviceOnlineDurationProps {
    eventManager: EventManager,
    today?: boolean
}

let DeviceOnlineDuration: React.FC<DeviceOnlineDurationProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();

    // ref
    let carouselRef = useRef<any>();

    // state
    let [deviceViews, setDeviceViews] = useState([]);

    // onload
    let initializeCarousel = (dom)=> {
        if (dom) {
            carouselRef.current = AutoScrollUtils.addAutoScroll(dom);
        } else {
            if (carouselRef.current) {
                AutoScrollUtils.removeAutoScroll(carouselRef.current);
            }
        }
    };
    
    let onDeviceSelected = (deviceId: string)=> {
        props.eventManager.notifyDeviceSelected(deviceId);
    };

    useEffect(()=> {
        let loadDeviceViews = async ()=> {
            let deviceViews = (await deviceApi.getAllDeviceViews()).data.data;
            deviceViews = deviceViews.map((e)=> {
                e.node.totalOnlineTime = e.node.totalOnlineTime == null ? 0 : e.node.totalOnlineTime;
                e.node.onlineDuration = parseInt(e.node.totalOnlineTime);
                if (props.today) {
                    if (new Date(Number(e.node.onlineTime)).getDate() != new Date().getDate()) {
                        e.node.onlineDuration = 0;
                    } else {
                        e.node.onlineDuration = new Date().getTime() - e.node.onlineTime;
                    }
                }
                return e;
            }).sort((a, b)=> {
                let aOnlineTime = a.node.onlineDuration;
                let bOnlineTime = b.node.onlineDuration;
                return bOnlineTime - aOnlineTime;
            });
            setDeviceViews(deviceViews);
        }
        loadDeviceViews();

        let refreshTask = setInterval(()=> {
            loadDeviceViews();
        }, 120000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    // render
    let getOnlineDurationUsedPercent = (deviceView)=> {
        let maxOnlineTime = deviceViews[0].node.onlineDuration;
        for(let fullOnlineTime of [
            1000 * 60 * 10,
            1000 * 60 * 30,
            1000 * 60 * 60,
            1000 * 60 * 60 * 24,
            1000 * 60 * 60 * 24 * 5,
            1000 * 60 * 60 * 24 * 15,
            1000 * 60 * 60 * 24 * 30,
            1000 * 60 * 60 * 24 * 30 * 3,
            1000 * 60 * 60 * 24 * 30 * 6,
            1000 * 60 * 60 * 24 * 30 * 12,
            1000 * 60 * 60 * 24 * 30 * 12 * 3,
        ]) {
            if (fullOnlineTime > maxOnlineTime) {
                let percent = (deviceView.node.onlineDuration / fullOnlineTime) * 100;
                percent = percent <= 1 ? 1 : percent;
                return percent;
            }
        }
        return 100;
    }

    const conicColors: ProgressProps['strokeColor'] = {
        '0%': 'rgba(22, 120, 255, 0.5)',
        '20%': 'rgba(22, 120, 255, 0.8)',
        '100%': 'rgba(255, 255, 255, 0.95)',
    };

    return (
        <DeviceOnlineDurationWrapper>
            <Content ref={(dom)=> initializeCarousel(dom)}>
                {
                    deviceViews.map((e, index)=> {
                        return <DeviceOnlineDurationItem key={e.device.id} onClick={()=> onDeviceSelected(e.device.id)}>
                            <DeviceIndex>NO.{index+1}</DeviceIndex>
                            <DeviceNameAndDuration>
                                <DeviceName>{e.device.name}</DeviceName>
                                <Progress 
                                    size="small"
                                    status="active"
                                    showInfo={true}
                                    percentPosition={{ align: 'end', type: 'outer' }}
                                    strokeColor={conicColors}
                                    percent={getOnlineDurationUsedPercent(e)} 
                                    format={(percent, successPercent)=> {
                                        return <DurationText>{TimeUtils.durationToText(e.node.onlineDuration)}</DurationText>;
                                    }}
                                />
                            </DeviceNameAndDuration>
                        </DeviceOnlineDurationItem>
                    })
                }
            </Content>
        </DeviceOnlineDurationWrapper>
    )
};

export default DeviceOnlineDuration;
