
class EventManager {

    private latestDeviceMetricsUpdatedListeners: LatestDeviceMetricsUpdatedListener[] = [];

    private deviceMetricSelectedListeners: DeviceMetricSelectedListener[] =[];

    public addLatestDeviceMetricsUpdatedListener(listener: LatestDeviceMetricsUpdatedListener): void {
        if (this.latestDeviceMetricsUpdatedListeners.indexOf(listener)>=0) {
            return;
        }
        this.latestDeviceMetricsUpdatedListeners.push(listener);
    }

    public removeLatestDeviceMetricsUpdatedListener(listener: LatestDeviceMetricsUpdatedListener): void {
        let index = this.latestDeviceMetricsUpdatedListeners.indexOf(listener);
        if (index < 0) {
            return;
        }
        this.latestDeviceMetricsUpdatedListeners.splice(index, 1);
    }

    public notifyLatestDeviceUpdated(latestDeviceMetrics: any): void {
        for(let listener of this.latestDeviceMetricsUpdatedListeners) {
            listener(latestDeviceMetrics);
        }
    }
    
    public addDeviceMetricSelectedListener(listener: DeviceMetricSelectedListener): void {
        if (this.deviceMetricSelectedListeners.indexOf(listener)>=0) {
            return;
        }
        this.deviceMetricSelectedListeners.push(listener);
    }

    public removeDeviceMetricSelectedListener(listener: DeviceMetricSelectedListener): void {
        let index = this.deviceMetricSelectedListeners.indexOf(listener);
        if (index < 0) {
            return;
        }
        this.deviceMetricSelectedListeners.splice(index, 1);
    }

    public notifyDeviceMetricSelected(property: any, selectedDeviceMetric: any): void {
        for(let listener of this.deviceMetricSelectedListeners) {
            listener(property, selectedDeviceMetric);
        }
    }
    
}

interface LatestDeviceMetricsUpdatedListener {
    (latestDeviceMetrics: any): void
}

interface DeviceMetricSelectedListener {
    (property: any, deviceMetric: any): void
}

export default EventManager;
