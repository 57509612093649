import { memo, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import TWEEN, { Tween } from "three/examples/jsm/libs/tween.module.js";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { DeviceModel3DWrapper } from "./DeviceModel3D.styled";
import EventManager from '../../common/event-manager/EventManager';
import HtmlDomUtils from '../../../../../util/HtmlDomUtils';

interface DeviceModel3DProps {
    eventManager: EventManager
}

let DeviceModel3D: React.FC<DeviceModel3DProps> = memo((props)=> {
    let uiContextRef = useRef ({
        autoPlaying: true,
        autoPlayingProspective: null as any,
        autoPlayingProspectiveIndex: 0,
        mouseMovePointer: null as {x: number, y: number},
        
        mouseClickPointer: null as {x: number, y: number},
        mouseClickFocusTweening: false,
        mouseClickIntersectedObjects: null as any[],
        mouseClickIntersectedObjectsDepth: 0,
        mouseClickSelectedObject: null as any,

        originalObjectMaterialMap: new Map<any, any>(),

        latestDeviceMetrics: null as any,
        selectedDeviceMetrics: null as any,
        selectedDeviceMetricsProspective: null as any,
    });

    let threeContextRef = useRef(null as {
        scene: any,
        renderer: any,
        camera: any,
    });

    let destroyScene = ()=> {
        if (threeContextRef.current) {
            let threeContext = threeContextRef.current;
            threeContextRef.current = null;
            threeContext.renderer.domElement.addEventListener('dblclick', null, false);
            threeContext.renderer.domElement.remove();
        }
    }

    let initializeScene = async (dom: HTMLElement)=> {
        if (dom == null) {
            destroyScene();
            return;
        }
        
        let uiContext = uiContextRef.current;

        dom.addEventListener('mouseover', (e)=> {
            uiContext.autoPlaying = false;
        });

        dom.addEventListener('mousemove', (e)=> {
            let mouseMovePointer = {
                x: (e.offsetX - dom.clientWidth / 2) / (dom.clientWidth / 2),
                y: - (e.offsetY - dom.clientHeight / 2) / (dom.clientHeight / 2),
            };
            uiContext.mouseMovePointer = mouseMovePointer;
        });

        dom.addEventListener('mouseout', (e)=> {
            uiContext.autoPlaying = true;
        });

        dom.addEventListener('dblclick', (e)=> {
            let mouseClickPointer = {
                x: (e.offsetX - dom.clientWidth / 2) / (dom.clientWidth / 2),
                y: - (e.offsetY - dom.clientHeight / 2) / (dom.clientHeight / 2),
            };
            uiContext.mouseClickPointer = mouseClickPointer;
            uiContext.mouseClickFocusTweening = true;
            uiContext.selectedDeviceMetricsProspective = null;
            
            let camera = threeContextRef.current?.camera;
            if (e.ctrlKey) {
                uiContext.mouseClickIntersectedObjectsDepth++;
            } else {
                uiContext.mouseClickIntersectedObjectsDepth=0;
            }
        });

        dom.addEventListener('click', (e)=> {
            console.log("x: ", camera?.position?.x, ", y: ", camera?.position?.y, ", z: ", camera?.position?.z);
        });

        let loader = new GLTFLoader();
        let backgroundGltf: any = await new Promise((resolve, reject)=> {
            loader.load('/static/3d-model/background.glb', (gltf)=> {
                resolve(gltf);
            }, undefined, (error)=> {
                reject(error);
            });
        });
        backgroundGltf.scene.scale.set(30, 30, 30);
        backgroundGltf.scene.position.y -= 100;

        let winchGltf: any = await new Promise((resolve, reject)=> {
            loader.load('/static/3d-model/winch-model-1.0.0.glb', (gltf)=> {
                resolve(gltf);
            }, undefined, (error)=> {
                reject(error);
            });
        });
        winchGltf.scene.scale.set(200, 200, 200);

        // for highlight/selected
        let wireFrameMaterial = new THREE.MeshBasicMaterial({ 
            wireframe: true ,
            transparent: true,
            opacity: 0.2,
            color: '#E8870D',
        });

        
        // auto playing prospectives
        let autoPlayingProspectives = [
            {
                objectName: '行星减速机',
                object: null as any,
                cameraPosition: {x: 10.765962588695082 , y:  -7.230833094210927 , z:  84.64408814080998},
            }, {
                objectName: '全盘湿式多片离合制动器',
                object: null as any,
                cameraPosition: {x: 76.00283824955456 , y:  5.812882516403769 , z:  132.05936329752478},
                metricsNames: ['减速器温度1', '减速器温度2'],
                metricsKeys: ['temperature3', 'temperature4'],
            }, {
                objectName: '离合制动器-外端盖',
                object: null as any,
                cameraPosition: {x:  180.63165361364025 , y:  -11.777681058711904 , z:  38.66830966799063},
                metricsNames: ['液压压力', '液压温度'],
                metricsKeys: ['pressure2', 'temperature2'],
            }, {
                objectName: '行星齿轮轴',
                object: null as any,
                cameraPosition: {x: -20.710227714308406 , y:  37.870985472722886 , z:  142.82323110289022},
            }, {
                objectName: '全盘湿式多片驻车制动器',
                object: null as any,
                cameraPosition: {x: -131.50885194916114 , y:  28.435911454829384 , z:  121.44836252697253},
                metricsNames: ['驻车温度', '驻车压力'],
                metricsKeys: ['temperature1', 'pressure1'],
            }, {
                objectName: '太阳轴',
                object: null as any,
                cameraPosition: {x:  61.68361344102594 , y:  31.412780400740377 , z:  -149.43025236913633},
            }, {
                objectName: '减速器-法兰盘',
                object: null as any,
                cameraPosition: {x:  98.13174235518808 , y:  18.557658277509105 , z:  -72.32597417970264},
            }, {
                objectName: '行星减速机-法兰盘',
                object: null as any,
                cameraPosition: {x:  -128.72366219595338 , y:  -7.481844287290733 , z:  113.04720932535695},
            }
        ];

        let getObjectNameText = (objectName: string)=> {
            if (objectName.indexOf(".") > 0) {
                objectName = objectName.substring(0, objectName.indexOf("."));
            }
            return objectName;
        }

        let getObjectLabelContent = (objectName: string)=> {
            objectName = getObjectNameText(objectName);

            // wrapper element
            let contentElement = document.createElement('div');
            contentElement.style.display = 'flex';
            contentElement.style.flexDirection = 'column';
            contentElement.style.alignItems = 'stretch';

            // object name
            let objectNameLabel = document.createElement('div');
            objectNameLabel.innerText = '部件名称: ';
            
            let objectNameText = document.createElement('div');
            objectNameText.innerText = getObjectNameText(objectName);

            let objectNameWrapper = document.createElement('div');
            contentElement.appendChild(objectNameWrapper);
            objectNameWrapper.appendChild(objectNameLabel);
            objectNameWrapper.appendChild(objectNameText);
            objectNameWrapper.style.display = 'flex';
            objectNameWrapper.style.justifyContent = 'space-between';
            objectNameWrapper.style.alignItems = 'center';

            // device metrics value
            let metricsNames = [];
            let metricsKeys = [];
            for(let autoPlayingProspective of autoPlayingProspectives) {
                if (autoPlayingProspective.objectName == objectName) {
                    metricsNames = autoPlayingProspective.metricsNames;
                    metricsKeys = autoPlayingProspective.metricsKeys;
                    break;
                }
            }

            if (uiContext.latestDeviceMetrics && metricsKeys && metricsKeys.length > 0) {
                let metricsListWrapper = document.createElement('div');
                contentElement.appendChild(metricsListWrapper);
                metricsListWrapper.style.marginTop = '5px';

                for(let i=0; i<metricsKeys.length; i++) {
                    let metricsKey = metricsKeys[i];
                    let metricsName = metricsNames[i];

                    let metricsNameLabel = document.createElement('div');
                    metricsNameLabel.innerText = metricsName;
                    
                    let metricsValueText = document.createElement('div');
                    metricsValueText.innerText = uiContext.latestDeviceMetrics[metricsKey]?.value;

                    let metricsWrapper = document.createElement('div');
                    metricsListWrapper.appendChild(metricsWrapper);
                    metricsWrapper.appendChild(metricsNameLabel);
                    metricsWrapper.appendChild(metricsValueText);
                    metricsWrapper.style.display = 'flex';
                    metricsWrapper.style.justifyContent = 'space-between';
                    metricsWrapper.style.alignItems = 'center';
                    metricsWrapper.style.padding = '5px 0px 5px 0px';
                }
            }

            return contentElement;
        }

        winchGltf.scene.traverse((child)=> {
            uiContext.originalObjectMaterialMap.set(child, child.material);
            
            // match the auto playing objects
            for(let autoPlayingProspective of autoPlayingProspectives) {
                if (autoPlayingProspective.objectName == getObjectNameText(child.name)) {
                    autoPlayingProspective.object = child;
                    break; // found
                }
            }
        });

        // scene
        let scene = new THREE.Scene();
        scene.background = null;
        scene.add(winchGltf.scene);

        backgroundGltf.scene.background = null;
        winchGltf.scene.background = null;

        // light
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(100, 100, 100);
        scene.add(directionalLight);

        const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight1.position.set(-100, -100, -100);
        scene.add(directionalLight1);

        // camera
        let camera = new THREE.PerspectiveCamera(75, dom.clientWidth / dom.clientHeight, 0.1, 1000);
        let cameraRadius = Math.sqrt(100*100*3);
        camera.position.x = cameraRadius;
        camera.position.y = cameraRadius;
        camera.position.z = cameraRadius;
        camera.lookAt(0, 0, 0);
        camera.layers.enableAll();

        // axes helper
        let axesHelper = new THREE.AxesHelper(100);
        // scene.add(axesHelper);

        // renderer
        let renderer = new THREE.WebGLRenderer({alpha: true});
        renderer.setClearAlpha(0);
        renderer.setSize(dom.clientWidth, dom.clientHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        dom.appendChild(renderer.domElement);

        let labelRenderer = new CSS2DRenderer();
        labelRenderer.setSize(dom.clientWidth, dom.clientHeight);
        labelRenderer.domElement.style.position = 'absolute';
        labelRenderer.domElement.style.top = '0';
        labelRenderer.domElement.style.left = '0';
        dom.appendChild(labelRenderer.domElement);

        let objectLabel = document.createElement('div');
        objectLabel.style.display = 'none';
        objectLabel.style.background = 'rgba(0, 0, 0, 0.5)';
        objectLabel.style.padding = '10px';
        objectLabel.style.textAlign = 'center';
        objectLabel.style.border = '2px solid rgba(255, 255, 255, 0.5)';
        objectLabel.style.borderRadius = '3px';
        labelRenderer.domElement.appendChild(objectLabel);
        
        let objectLabelCss = new CSS2DObject(objectLabel);
        objectLabelCss.position.set(0, 0, 0);
        objectLabelCss.center.set(0, 0);
        objectLabelCss.layers.set(0);

        // raycaster
        let raycaster = new THREE.Raycaster();

        // orbit controls
        let orbitControls = new OrbitControls(camera, labelRenderer.domElement);
        orbitControls.update();

        // set context
        threeContextRef.current = {
            scene: scene,
            camera: camera,
            renderer: renderer,
        };

        // animation
        let animate = ()=> {
            if (threeContextRef.current==null) {
                return;
            }

            if (uiContext.mouseClickFocusTweening) {
                uiContext.mouseClickFocusTweening = false;

                let mouseClickPointerVector = new THREE.Vector2();
                let mouseClickPointer = uiContext.mouseClickPointer;
                mouseClickPointerVector.x = mouseClickPointer.x;
                mouseClickPointerVector.y = mouseClickPointer.y;
                raycaster.setFromCamera(mouseClickPointer, camera);

                let children = [];
                scene.traverse((child)=> {
                    children.push(child);
                });
                
                let selectedIntersect = null;
                let intersects = raycaster.intersectObjects(children);
                if (intersects.length > 0) {
                    selectedIntersect = intersects[uiContext.mouseClickIntersectedObjectsDepth % intersects.length];
                }
                
                if (selectedIntersect && uiContext.originalObjectMaterialMap.has(selectedIntersect.object)) {
                    // resotre previous selected object material
                    let autoPlayingProspective = uiContext.autoPlayingProspective;
                    if (autoPlayingProspective.object) {
                        autoPlayingProspective.object.remove(objectLabelCss);
                        autoPlayingProspective.object.material = uiContext.originalObjectMaterialMap.get(autoPlayingProspective.object);
                    }

                    if (uiContext.mouseClickSelectedObject) {
                        uiContext.mouseClickSelectedObject.remove(objectLabelCss);
                        uiContext.mouseClickSelectedObject.material = uiContext.originalObjectMaterialMap.get(uiContext.mouseClickSelectedObject);
                    }
                    uiContext.mouseClickSelectedObject = selectedIntersect.object;
                    uiContext.mouseClickSelectedObject.material = wireFrameMaterial;
                    selectedIntersect.object.add(objectLabelCss);
                    HtmlDomUtils.removeChildren(objectLabel);
                    objectLabel.appendChild(getObjectLabelContent(selectedIntersect.object.name));
                    objectLabel.style.display = 'block';

                    let intersectPoint = selectedIntersect.point;
                    let targetPosition = new THREE.Vector3(0, 0, 0);
                    targetPosition.x = intersectPoint.x * 2;
                    targetPosition.y = intersectPoint.y * 2;
                    targetPosition.z = intersectPoint.z * 2;
                    setTimeout(()=> {
                        let tween = new Tween(camera.position);
                        tween.to(targetPosition, 1000);
                        tween.easing(TWEEN.Easing.Cubic.InOut);
                        tween.onUpdate((position)=> {
                        });
                        tween.onComplete(()=> {
                        });
                        tween.start();
                    });
                }
            }
            TWEEN.update();
            requestAnimationFrame(animate);

            orbitControls.update();
            renderer.render(scene, camera);
            labelRenderer.render(scene, camera);
        }
        animate();

        let autoPlay = (autoPlayingProspective: any)=> {
            let prevAutoPlayingProspective = uiContext.autoPlayingProspective;
            if (prevAutoPlayingProspective != null && prevAutoPlayingProspective.object) {
                prevAutoPlayingProspective.object.remove(objectLabelCss);
                prevAutoPlayingProspective.object.material = uiContext.originalObjectMaterialMap.get(prevAutoPlayingProspective.object);
            }
            uiContext.autoPlayingProspective = autoPlayingProspective;
            if (autoPlayingProspective.object) {
                autoPlayingProspective.object.material = wireFrameMaterial;
            } else {
                console.log("Cannot find object", autoPlayingProspective.objectName);
            }
            autoPlayingProspective.object.add(objectLabelCss);
            HtmlDomUtils.removeChildren(objectLabel);
            objectLabel.appendChild(getObjectLabelContent(autoPlayingProspective.object.name));
            objectLabel.style.display = 'block';

            let targetPosition = new THREE.Vector3(0, 0, 0);
            targetPosition.x = autoPlayingProspective.cameraPosition.x;
            targetPosition.y = autoPlayingProspective.cameraPosition.y;
            targetPosition.z = autoPlayingProspective.cameraPosition.z;

            let tween = new Tween(camera.position);
            tween.to(targetPosition, 1000);
            tween.easing(TWEEN.Easing.Cubic.InOut);
            tween.onUpdate((position)=> {
            });
            tween.onComplete(()=> {
            });
            tween.start();
        }

        let autoPlayTask = ()=> {
            if (threeContextRef.current == null) {
                clearInterval(autoPlayingInterval);
                return;
            }
            if (uiContext.selectedDeviceMetricsProspective) {
                return;
            }
            if (uiContext.autoPlaying) {
                uiContext.autoPlayingProspectiveIndex = (uiContext.autoPlayingProspectiveIndex + 1) % autoPlayingProspectives.length;
                let autoPlayingProspective = autoPlayingProspectives[uiContext.autoPlayingProspectiveIndex];
                autoPlay(autoPlayingProspective);
            }
        };
        let autoPlayingInterval = setInterval(autoPlayTask, 4000);
        autoPlayTask();

        // on metrics updated
        props.eventManager.addLatestDeviceMetricsUpdatedListener((latestDeviceMetrics)=> {
            uiContext.latestDeviceMetrics = latestDeviceMetrics;
        });

        props.eventManager.addDeviceMetricSelectedListener((property, deviceMetric)=> {
            uiContext.selectedDeviceMetrics = deviceMetric;
            for(let autoPlayingProspective of autoPlayingProspectives) {
                if (autoPlayingProspective.metricsNames != null && autoPlayingProspective.metricsNames.indexOf(property.name)>=0) {
                    uiContext.selectedDeviceMetricsProspective = autoPlayingProspective;
                    autoPlay(uiContext.selectedDeviceMetricsProspective);
                    break;
                }
            }
        });
    };

    useEffect(()=> {
        return ()=> {
            destroyScene();
        }
    });

    // render
    return <DeviceModel3DWrapper ref={(dom)=> initializeScene(dom)}>
    </DeviceModel3DWrapper>
});

export default DeviceModel3D;